export default class ConstructionSite {
  constructor (constructionSite) {
    this.id = constructionSite.id
    this.name = constructionSite.name
    this.imageId = constructionSite.imageId
    this.imageUrl = constructionSite.imageUrl
    this.role = constructionSite.role
    this.projectFiles = constructionSite.projectFiles
    this.documents = constructionSite.documents
    this.users = constructionSite.users
    this.lastUpdate = new Date().getTime()
  }
}
