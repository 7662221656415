import SecureStorage from '@/services/SecureStorage'
const ls = SecureStorage.get()

class TokenService {
  getToken () {
    return ls.get('user')
  }
}

export default new TokenService()
