import MainService from '@/services/main.service'
import ProjectFileService from '@/services/projectFile.service'
import DocumentService from '@/services/document.service'
import FaultService from '@/services/fault.service'
import ProjectFiles from '@/models/project_files_model'
import Fault from '@/models/fault_model'
import Photo from '@/models/photo_model'

const state = {
  projectFiles: null,
  documents: null,
  users: null,
  faultChat: [],
  faultForUpdate: null
}

const getters = {}

const actions = {
  fetch ({ commit }, id) {
    return MainService.parseInObjectDetailsScreen(id).then(
      data => {
        commit('fetchSuccess', data)
        return Promise.resolve(data)
      },
      error => {
        commit('fetchFailure')
        return Promise.reject(error)
      }
    )
  },
  add ({ commit }, payload) {
    return ProjectFileService.add(payload.constructionSiteId, payload.projectFile, payload.formData).then(
      response => {
        const projectFileWithImage = new ProjectFiles(JSON.parse(JSON.stringify(payload.projectFile)))
        projectFileWithImage.imageUrl = URL.createObjectURL(payload.file)

        commit('addProjectFile', projectFileWithImage)
        return Promise.resolve(projectFileWithImage)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  delete ({ commit }, payload) {
    return ProjectFileService.delete(payload.constructionSiteId, payload.projectFileId).then(
      res => {
        commit('removeProjectFile', payload.projectFileId)
        return Promise.resolve(payload.projectFileId)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  update ({ commit }, payload) {
    return ProjectFileService.update(payload.constructionSiteId, payload.projectFile).then(
      res => {
        commit('saveProjectFile', payload.projectFile)
        return Promise.resolve(payload.projectFile)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  deleteDocument ({ commit }, payload) {
    return DocumentService.delete(payload.constructionSiteId, payload.documentId).then(
      res => {
        commit('removeDocument', payload.documentId)
        return Promise.resolve(payload.documentId)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  downloadDocument ({ commit }, payload) {
    return DocumentService.download(payload.constructionSiteId, payload.documentId).then(
      response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', payload.documentName)
        document.body.appendChild(fileLink)

        fileLink.click()

        return Promise.resolve(payload.documentId)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  uploadDocument ({ commit }, payload) {
    return DocumentService.upload(payload.constructionSiteId, payload.document.id, payload.formData).then(
      response => {
        if (response.status === 200) {
          commit('addDocument', payload.document)
          return Promise.resolve(payload.document)
        }
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  fetchFault ({ commit }, payload) {
    return MainService.parseInFaultDetailsScreen(payload.constructionSiteId, payload.projectFileId, payload.faultId).then(
      data => {
        commit('fetchFaultSuccess', { data: data, projectFileId: payload.projectFileId, faultId: payload.faultId })
        return Promise.resolve(data)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  addFault ({ commit }, payload) {
    return FaultService.add(payload.constructionSiteId, payload.projectFileId, payload.fault).then(
      response => {
        commit('addFault', {
          projectFileId: payload.projectFileId,
          fault: payload.fault
        })
        return Promise.resolve({
          projectFileId: payload.projectFileId,
          fault: payload.fault
        })
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  updateFault ({ commit }, payload) {
    return FaultService.update(payload.constructionSiteId, payload.projectFileId, payload.fault).then(
      response => {
        commit('saveFault', {
          projectFileId: payload.projectFileId,
          fault: payload.fault
        })
        return Promise.resolve({
          projectFileId: payload.projectFileId,
          fault: payload.fault
        })
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  removeFault ({ commit }, payload) {
    return FaultService.delete(payload.constructionSiteId, payload.projectFileId, payload.fault.id).then(
      response => {
        commit('deleteFault', {
          projectFileId: payload.projectFileId,
          fault: payload.fault
        })
        return Promise.resolve(true)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  uploadFaultPhoto ({ commit }, payload) {
    return FaultService.uploadImage(payload.constructionSiteId, payload.projectFileId, payload.faultId, payload.formData).then(
      res => {
        const photoUrl = URL.createObjectURL(payload.file)
        const newPhoto = new Photo({
          id: payload.id,
          name: payload.fileName,
          imageUrl: photoUrl
        })
        const updatedFault = new Fault((JSON.parse(JSON.stringify(payload.fault))))
        updatedFault.photos.push(newPhoto)

        commit('saveFault', {
          projectFileId: payload.projectFileId,
          fault: updatedFault
        })
        return Promise.resolve(newPhoto)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  removeFaultPhoto ({ commit }, payload) {
    return FaultService.removeImage(payload.constructionSiteId, payload.projectFileId, payload.faultId, payload.faultPhotoId).then(
      res => {
        commit('removeFaultPhoto', {
          projectFileId: payload.projectFileId,
          faultId: payload.faultId,
          faultPhotoId: payload.faultPhotoId
        })
        return Promise.resolve(payload.projectFileId)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  uploadFaultRecording ({ commit }, payload) {
    try {
      return FaultService.uploadRecording(payload.constructionSiteId, payload.projectFileId, payload.faultId, payload.formData)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  faultForUpdate ({ commit }, payload) {
    commit('changeFaultForUpdate', payload)
  },
  deleteStore ({ commit }) {
    commit('clearStore')
  }
}

const mutations = {
  fetchSuccess (state, data) {
    state.projectFiles = data.projectFiles
    state.documents = data.documents
    state.users = data.users
  },
  fetchFailure (state) {
    state.projectFiles = null
    state.documents = null
    state.users = null
  },
  fetchFaultSuccess (state, payload) {
    for (let i = 0; state.projectFiles.length; i++) {
      if (state.projectFiles[i].id === payload.projectFileId) {
        const updatedProject = new ProjectFiles(JSON.parse(JSON.stringify(state.projectFiles[i])))
        for (let j = 0; j < updatedProject.faults.length; j++) {
          if (updatedProject.faults[j].id === payload.faultId) {
            updatedProject.faults[j] = payload.data
          }
        }
        state.projectFiles[i] = updatedProject
        return
      }
    }
  },
  addProjectFile (state, newProjectFile) {
    state.projectFiles.push(newProjectFile)
  },
  removeProjectFile (state, id) {
    let indexOfElementToRemove
    for (let i = 0; i < state.projectFiles.length; i++) {
      if (state.projectFiles[i].id === id) {
        indexOfElementToRemove = i
        break
      }
    }
    state.projectFiles.splice(indexOfElementToRemove, 1)
  },
  saveProjectFile (state, projectFile) {
    for (let i = 0; i < state.projectFiles.length; i++) {
      if (state.projectFiles[i].id === projectFile.id) {
        state.projectFiles[i] = new ProjectFiles(JSON.parse(JSON.stringify(projectFile)))
        return
      }
    }
  },
  removeDocument (state, id) {
    let indexOfElementToRemove
    for (let i = 0; i < state.documents.length; i++) {
      if (state.documents[i].id === id) {
        indexOfElementToRemove = i
        break
      }
    }
    state.documents.splice(indexOfElementToRemove, 1)
  },
  addDocument (state, newDocument) {
    state.documents.push(newDocument)
  },
  addFault (state, payload) {
    for (let i = 0; i < state.projectFiles.length; i++) {
      if (state.projectFiles[i].id === payload.projectFileId) {
        const projectFileToUpdate = new ProjectFiles(JSON.parse(JSON.stringify(state.projectFiles[i])))
        projectFileToUpdate.faults.push(new Fault(JSON.parse(JSON.stringify(payload.fault))))
        state.projectFiles[i] = projectFileToUpdate
        return
      }
    }
  },
  saveFault (state, payload) {
    for (let i = 0; state.projectFiles.length; i++) {
      if (state.projectFiles[i].id === payload.projectFileId) {
        for (let j = 0; j < state.projectFiles[i].faults.length; j++) {
          if (state.projectFiles[i].faults[j].id === payload.fault.id) {
            state.projectFiles[i].faults[j] = payload.fault
          }
        }
        return
      }
    }
  },
  deleteFault (state, payload) {
    for (let i = 0; state.projectFiles.length; i++) {
      if (state.projectFiles[i].id === payload.projectFileId) {
        const updatedProject = new ProjectFiles(JSON.parse(JSON.stringify(state.projectFiles[i])))
        for (let j = 0; j < updatedProject.faults.length; j++) {
          if (updatedProject.faults[j].id === payload.fault.id) {
            updatedProject.faults.splice(updatedProject.faults.indexOf(updatedProject.faults[j]), 1)
            // updatedProject.faults[j] = payload.fault
          }
        }
        state.projectFiles[i] = updatedProject
        return
      }
    }
  },
  removeFaultPhoto (state, payload) {
    for (let i = 0; state.projectFiles.length; i++) {
      if (state.projectFiles[i].id === payload.projectFileId) {
        for (let j = 0; j < state.projectFiles[i].faults.length; j++) {
          if (state.projectFiles[i].faults[j].id === payload.faultId) {
            const updatedFault = new Fault(JSON.parse(JSON.stringify(state.projectFiles[i].faults[j])))
            for (let k = 0; k < updatedFault.photos.length; k++) {
              if (updatedFault.photos[k].id === payload.faultPhotoId) {
                updatedFault.photos.splice(k, 1)
              }
            }
            state.projectFiles[i].faults[j] = updatedFault
            return
          }
        }
      }
    }
  },
  changeFaultForUpdate (state, payload) {
    state.faultForUpdate = payload
  },
  clearStore (state) {
    state = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
