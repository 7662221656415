<template>
  <div class="constructionSiteCardWrapper p-shadow-1">
    <div v-if="projectFile.imageUrl !== undefined"
         class="image"
         :style="{ backgroundImage: `url('${projectFile.imageUrl}')`}" @click="goToProjectCanvas">
    </div>
    <div v-else class="no-image" @click="goToProjectCanvas">
      <div class="p-d-flex p-dir-col p-jc-center p-ai-center no-image-text">
        <h3>Brak zdjęcia.</h3>
      </div>
    </div>

    <div class="details">
      <h3>{{ projectFile.name }}</h3>
      <span>{{ projectFile.type }}</span>
      <div class="icons" v-if="constructionSite[0].role === 'Owner'">
        <i class="pi pi-pencil" @click.prevent="toggleChangeNameProjectFileModal"/>
        <i class="pi pi-key" @click.prevent="toggleChangeTypeProjectFileModal"/>
        <i class="pi pi-trash" @click.prevent="toggleRemoveProjectFileModal"/>
      </div>
      <div class="icons" v-else style="justify-content: center">
        <!-- Trzeba dodać funkcjonalnosc -->
        <i class="pi pi-heart" @click.prevent=""/>
      </div>
    </div>
  </div>

  <ConfirmationModal
    text="Czy jesteś pewien, że chcesz usunąć ten projekt?"
    v-model:visible="removeProjectFileModal"
    :noPressed="toggleRemoveProjectFileModal"
    :yesPressed="submitRemoveProjectFileModal"
  />

  <BasicFormModal
    v-model:modelValue="changeNameProjectFileModalValue"
    v-model:visible="changeNameProjectFileModal"
    :submit="submitChangeNameProjectFile"
    :toggle="toggleChangeNameProjectFileModal"
    header="Zmiana nazwy projektu"
    placeholder="Nazwa projektu"
  />

  <BasicFormModal
    v-model:modelValue="changeTypeProjectFileModalValue"
    v-model:visible="changeTypeProjectFileModal"
    :submit="submitChangeTypeProjectFile"
    :toggle="toggleChangeTypeProjectFileModal"
    header="Zmiana typu projektu"
    placeholder="Typ projektu"
  />
</template>

<script>
import { ref, computed } from 'vue'
import router from '@/router'
import { useToast } from 'primevue/usetoast'
import { useStore } from 'vuex'

import ConfirmationModal from '@/components/modals/ConfirmationModal'
import BasicFormModal from '@/components/modals/BasicFormModal'

export default {
  name: 'ProjectFileContentCard',
  components: {
    ConfirmationModal,
    BasicFormModal
  },
  props: {
    projectFile: {
      type: Object,
      required: true
    },
    constructionSiteId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const toast = useToast()
    const store = useStore()
    const constructionSite = computed(() => store.state.constructionSiteStore.constructionSites.filter(constuctionSite => constuctionSite.id === props.constructionSiteId))

    const removeProjectFileModal = ref(false)
    const changeNameProjectFileModal = ref(false)
    const changeTypeProjectFileModal = ref(false)

    const changeNameProjectFileModalValue = ref(props.projectFile.name)
    const changeTypeProjectFileModalValue = ref(props.projectFile.type)

    const toggleRemoveProjectFileModal = () => {
      removeProjectFileModal.value = !removeProjectFileModal.value
    }

    const toggleChangeNameProjectFileModal = () => {
      changeNameProjectFileModal.value = !changeNameProjectFileModal.value
    }

    const toggleChangeTypeProjectFileModal = () => {
      changeTypeProjectFileModal.value = !changeTypeProjectFileModal.value
    }

    const submitRemoveProjectFileModal = () => {
      toggleRemoveProjectFileModal()
      store.dispatch('projectFileStore/delete', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFile.id
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Usuwanie projektu',
            detail: 'Pomyślnie usunięto projekt.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Usuwanie projektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const submitChangeNameProjectFile = () => {
      toggleChangeNameProjectFileModal()

      const updatedProjectFile = JSON.parse(JSON.stringify(props.projectFile))
      updatedProjectFile.name = changeNameProjectFileModalValue.value

      store.dispatch('projectFileStore/update', {
        constructionSiteId: props.constructionSiteId,
        projectFile: updatedProjectFile
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Modyfikacja projektu',
            detail: 'Pomyślnie zaktualizowano nazwę projektu.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Modyfikacja projektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const submitChangeTypeProjectFile = () => {
      toggleChangeTypeProjectFileModal()

      const updatedProjectFile = JSON.parse(JSON.stringify(props.projectFile))
      updatedProjectFile.type = changeTypeProjectFileModalValue.value

      store.dispatch('projectFileStore/update', {
        constructionSiteId: props.constructionSiteId,
        projectFile: updatedProjectFile
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Modyfikacja projektu',
            detail: 'Pomyślnie zaktualizowano typ projektu.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Modyfikacja projektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const goToProjectCanvas = async () => {
      await store.dispatch('projectFileStore/faultForUpdate', null)
      await router.push('/construction-site/' + props.constructionSiteId + '/project-file/' + props.projectFile.id)
    }

    return {
      removeProjectFileModal,
      toggleRemoveProjectFileModal,
      submitRemoveProjectFileModal,
      changeNameProjectFileModal,
      changeTypeProjectFileModal,
      changeNameProjectFileModalValue,
      changeTypeProjectFileModalValue,
      toggleChangeNameProjectFileModal,
      toggleChangeTypeProjectFileModal,
      submitChangeNameProjectFile,
      submitChangeTypeProjectFile,
      goToProjectCanvas,
      constructionSite
    }
  }
}
</script>

<style lang="scss" scoped>
.constructionSiteCardWrapper {
  height: 320px;
  width: 100%;
  background: white;
  border-radius: 25px;

  .image {
    position: relative;
    height: 200px;
    -webkit-border-top-left-radius: 25px;
    -webkit-border-top-right-radius: 25px;
    -moz-border-radius-topleft: 25px;
    -moz-border-radius-topright: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  .no-image {
    position: relative;
    height: 200px;
    -webkit-border-top-left-radius: 25px;
    -webkit-border-top-right-radius: 25px;
    -moz-border-radius-topleft: 25px;
    -moz-border-radius-topright: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;

    .no-image-text{
      position: relative;
      border-style: solid;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-color: #f8f8f8;
      border-width: 1px;
      height: 200px;
      color: gray !important;
    }
  }

  .details {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icons {
      width: 100%;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 3.5em;

      i {
        font-size: 20px;
        cursor: pointer;
      }

      @media screen and (min-width: 1200px) {
        padding: 0 5em;
      }
    }
  }
}
</style>
