<template>
  <Navbar :items="navbarItems" screenName="projectFile"/>
  <div class="projectFileWrapper">
    <router-view v-slot="{ Component, route }">
      <component
        v-if="route.name === 'ProjectFileContent'"
        :is="Component"
        :isLoading="isLoading"
        :projectFiles="projectFiles"
        v-model:modelValue="searchValue"
      />
      <component
        v-if="route.name === 'ProjectFileContentFaults'"
        :is="Component"
        :isLoading="isLoading"
        :faultsWithCategory="faultsWithCategory"
        :users="users"
      />
      <component
        v-if="route.name === 'ProjectFileContentDocuments'"
        :is="Component"
        :isLoading="isLoading"
        :documents="documents"
      />
    </router-view>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { computed, onMounted, ref, provide } from 'vue'
import router from '@/router'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'ProjectFile',
  props: {
    constructionSiteId: {
      type: String,
      required: true
    }
  },
  components: { Navbar },
  setup (props) {
    const isLoading = ref(false)
    const loadingNewFaults = ref(false)
    const store = useStore()
    const toast = useToast()
    const searchValue = ref('')
    const navbarItems = [
      {
        id: 1,
        label: 'Projekty',
        to: '/construction-site/' + props.constructionSiteId
      },
      {
        id: 2,
        label: 'Usterki',
        to: '/construction-site/' + props.constructionSiteId + '/faults'
      },
      {
        id: 3,
        label: 'Dokumenty',
        to: '/construction-site/' + props.constructionSiteId + '/documents'
      }
    ]
    const faultsWithCategory = ref({
      faultCategoryUnassigned: [],
      faultCategoryGeneral: [],
      faultCategoryPlumbingAndCentralHeating: [],
      faultCategoryElectrical: [],
      faultCategoryFinishing: [],
      faultCategoryTelecom: [],
      faultCategoryVentilationAndAirCon: [],
      faultCategoryOther: [],
      faultsCategoryArchived: []
    })

    const users = computed(() => store.state.projectFileStore.users)
    const documents = computed(() => store.state.projectFileStore.documents)

    const projectFiles = computed(() => store.state.projectFileStore.projectFiles?.filter(projectFile => {
      return projectFile.name.toLowerCase().includes(searchValue.value.toLowerCase())
    }))
    const projectFilesFromStore = computed(() => store.state.projectFileStore.projectFiles)

    const updateFaults = () => {
      loadingNewFaults.value = true
      faultsWithCategory.value = filterFaults()
      loadingNewFaults.value = false
    }

    provide('updateFaults', updateFaults)

    const filterFaults = () => {
      const faultsData = []

      projectFilesFromStore.value.forEach((projectFile) => {
        projectFile.faults.forEach((fault) => {
          faultsData.push(fault)
        })
      })

      return {
        faultsCategoryArchived: faultsData.filter(function (fault) {
          return fault.isArchived === true
        }),
        // eslint-disable-next-line array-callback-return
        faultCategoryUnassigned: faultsData?.filter(function (fault) {
          if (fault.isArchived === false) {
            return fault.faultCategory === 'FaultCategory.Unassigned'
          }
        }),
        // eslint-disable-next-line array-callback-return
        faultCategoryGeneral: faultsData?.filter(function (fault) {
          if (fault.isArchived === false) {
            return fault.faultCategory === 'FaultCategory.General'
          }
        }),
        // eslint-disable-next-line array-callback-return
        faultCategoryPlumbingAndCentralHeating: faultsData?.filter(function (fault) {
          if (fault.isArchived === false) {
            return fault.faultCategory === 'FaultCategory.PlumbingAndCentralHeating'
          }
        }),
        // eslint-disable-next-line array-callback-return
        faultCategoryElectrical: faultsData?.filter(function (fault) {
          if (fault.isArchived === false) {
            return fault.faultCategory === 'FaultCategory.Electrical'
          }
        }),
        // eslint-disable-next-line array-callback-return
        faultCategoryFinishing: faultsData?.filter(function (fault) {
          if (fault.isArchived === false) {
            return fault.faultCategory === 'FaultCategory.Finishing'
          }
        }),
        // eslint-disable-next-line array-callback-return
        faultCategoryTelecom: faultsData?.filter(function (fault) {
          if (fault.isArchived === false) {
            return fault.faultCategory === 'FaultCategory.Telecom'
          }
        }),
        // eslint-disable-next-line array-callback-return
        faultCategoryVentilationAndAirCon: faultsData?.filter(function (fault) {
          if (fault.isArchived === false) {
            return fault.faultCategory === 'FaultCategory.VentilationAndAirCon'
          }
        }),
        // eslint-disable-next-line array-callback-return
        faultCategoryOther: faultsData?.filter(function (fault) {
          if (fault.isArchived === false) {
            return fault.faultCategory === 'FaultCategory.Other'
          }
        })
      }
    }
    provide('filterFaults', filterFaults)

    onMounted(async () => {
      isLoading.value = true

      store.dispatch('projectFileStore/fetch', props.constructionSiteId).then(
        () => {
          faultsWithCategory.value = filterFaults()
          isLoading.value = false
        },
        async (error) => {
          await router.push('/')
          toast.add({
            severity: 'warn',
            summary: 'Wystąpił błąd',
            detail: error,
            life: 3000
          })
        }
      )
    })

    return {
      projectFiles,
      faultsWithCategory,
      users,
      documents,
      isLoading,
      searchValue,
      navbarItems
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
