<template>
<div class="mobileWrapper">
  <div class="mobileCard p-d-flex p-flex-column p-jc-center p-shadow-1">
    <img :src="require('@/assets/logo.jpg')" alt="logo"/>
    <p> Skorzystaj z aplikacji na urządzenia mobilne dostępnej pod adresem.</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'MobileCard'
}
</script>

<style lang="scss" scoped>
.mobileWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .mobileCard {
    width: 90vw;
    background-color: white;
    padding: 25px 30px;
    border-radius: 15px;
    text-align: center;
  }

  img{
    align-self: center;
    width: 50%;
  }
}
</style>
