import SecureLS from 'secure-ls'

class SecureStorage {
  constructor () {
    this.ls = new SecureLS({ isCompression: false })
  }

  get () {
    return this.ls
  }
}

export default new SecureStorage()
