export default class Fault {
  constructor (fault) {
    this.id = fault.id
    this.name = fault.name
    this.description = fault.description
    this.faultProgressType = fault.faultProgressType
    this.faultPriority = fault.faultPriority
    this.faultCategory = fault.faultCategory
    this.markerPositionDx = fault.markerPositionDx
    this.markerPositionDy = fault.markerPositionDy
    this.isArchived = fault.isArchived
    this.isPaid = fault.isPaid
    this.price = fault.price
    this.ownerAccepted = fault.ownerAccepted
    this.workerAccepted = fault.workerAccepted
    this.photos = fault.photos
    this.recordings = fault.recordings
    this.users = fault.users
    this.emails = fault.emails
    this.deadline = fault.deadline
    this.lastUpdate = new Date().getTime()
    this.workflows = fault.workflows
    this.UserId = fault.UserId
    this.ProjectFileId = fault.ProjectFileId
  }
}
