<template>
  <div class="faultRecordingsContentWrapper">
    <Button @click="toggleFaultRecorderModal" label="Dodaj nowe nagranie" icon="pi pi-plus" iconPos="left" :disabled="isLoading"/>
    <div v-if="isLoading">
      <div class="recordingWrapper">
        <div class="recordingDetails">
          <Skeleton width="70px" height="16px"/>
          <br/>
          <Skeleton width="150px" height="12px"/>
        </div>
        <div class="recordingAudioPlayer">
          <Skeleton width="300px" height="50px"/>
        </div>
      </div>
    </div>
    <div v-if="!isLoading">
      <div v-if="fault.recordings.length === 0">
        <p> Brak nagrań w usterce </p>
      </div>
      <div v-else-if="fault.recordings.length > 0">
        <div v-for="(recording, index) in fault.recordings" :key="recording.id">
          <div class="p-d-flex" :style="recording.UserId === firstUserId ? { 'justify-content': `flex-start`} : { 'justify-content': `flex-end`}">
            <FaultContentRecordingTile :fault="fault" :index="index" :recording="recording"/>
          </div>
        </div>
      </div>
    </div>
    <Dialog header="Dodawanie nowego nagrania" v-model:visible='addRecorderModal' :modal="true">
      <div class="p-d-flex p-flex-column p-m-4 p-text-center">
        <div>
          <Button v-if='!running' label="Rozpocznij nagrywanie" @click='runRecording' class="p-m-3" :disabled='blob' />
          <Button v-if='running' label="Zatrzymaj nagrywanie" @click='stopRecording' class="p-m-3 p-button-outlined"/>
        </div>
        <div v-if='running' class="p-text-center">
          <p class="p-mb-4">Nagrywanie...</p>
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
        <div class="p-text-center p-mt-5 p-mb-3 p-d-flex p-flex-column" id='audioContainer'>
          <span v-if="blob" class="p-mb-4">Sprawdź swoje nagranie</span>
          <audio id='audio' controls class="p-mx-auto"></audio>
          <Button v-if="blob" label="Wyślij nagranie" @click='sendRecording' class="p-mt-3" />
          <Button v-if="blob" label="Resetuj" @click='resetRecordings' class="p-mt-3 p-button-outlined" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import { computed, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import FaultContentRecordingTile from '@/components/fault/FaultContentRecordingTile'
import { createId } from '@/utils/util'
import Fault from '@/models/fault_model'
import Recording from '@/models/recording_model'
import router from '@/router'

export default {
  name: 'FaultContentRecordings',
  components: {
    FaultContentRecordingTile,
    Skeleton,
    Dialog,
    Button
  },
  props: {
    fault: {
      required: true
    },
    constructionSiteId: {
      required: true
    },
    projectFileId: {
      required: true
    },
    faultId: {
      required: true
    },
    isLoading: {
      required: false
    }
  },
  setup (props) {
    const store = useStore()
    const toast = useToast()
    const addRecorderModal = ref(false)
    const running = ref(false)
    const toggleFaultRecorderModal = () => {
      if (running.value === true) running.value = false
      recorder.value = null
      blob.value = null
      chunks.value = []
      addRecorderModal.value = !addRecorderModal.value
    }
    const loggedUser = computed(() => store.state.authStore.userDetails)
    const device = ref()
    const chunks = ref([])
    const blob = ref()
    const recorder = ref()
    const runRecording = () => {
      device.value = navigator.mediaDevices.getUserMedia({ audio: true })
      device.value.then(stream => {
        running.value = true
        recorder.value = new MediaRecorder(stream)

        recorder.value.ondataavailable = e => {
          chunks.value.push(e.data)

          if (recorder.value.state === 'inactive') {
            blob.value = new Blob(chunks.value, { type: 'audio/mp3' })
            document.getElementById('audio').src = URL.createObjectURL(blob.value)
          }
        }

        recorder.value.start()
      }).catch(() => {
        toast.add({
          severity: 'error',
          summary: 'Wystąpił problem z pozwoleniem na użycie mikrofonu, bądź nie jest on dostępny.',
          detail: 'By nagrywanie działało prawidłowo udostępnij swój mikrofon klikając w górze przeglądarki na ikonę mikrofonu, bądź kamery i przywróć uprawnienia',
          life: 15000
        })
      })
    }
    const stopRecording = () => {
      running.value = false
      recorder.value.stop()
    }
    const sendRecording = async () => {
      const recordId = createId()
      const fileName = 'record_' + createId() + '.mp3'

      const formData = new FormData()
      formData.append('file', blob.value)
      formData.append('id', recordId)
      formData.append('fileName', fileName)

      await store.dispatch('projectFileStore/uploadFaultRecording', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFileId,
        faultId: props.fault.id,
        formData: formData,
        fault: props.fault
      }).then(
        async () => {
          toast.add({
            severity: 'success',
            summary: 'Dodawanie nagrania usterki',
            detail: 'Pomyślnie dodano nagranie usterki.',
            life: 3000
          })
          const faultToUpdate = new Fault(JSON.parse(JSON.stringify(props.fault)))
          const recordingsToUpdate = new Recording({
            id: recordId,
            name: fileName,
            date: Date.now(),
            UserId: loggedUser.value.id,
            recordingUrl: URL.createObjectURL(blob.value)
          })
          faultToUpdate.recordings.push(recordingsToUpdate)
          await store.dispatch('projectFileStore/updateFault', {
            constructionSiteId: props.constructionSiteId,
            projectFileId: props.projectFileId,
            fault: faultToUpdate
          })
          toggleFaultRecorderModal()
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Wystąpił problem z dodaniem nagrania usterki',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const resetRecordings = () => {
      document.getElementById('audio').src = ''
      recorder.value = null
      blob.value = null
      chunks.value = []
    }
    const firstUserId = computed(() => {
      if (props.fault.recordings.length > 0) {
        return props.fault.recordings[0].UserId
      }
      return ''
    })

    onUnmounted(async () => {
      await device.value?.then(stream => stream.getTracks().forEach(track => track.stop()))
      device.value = null
    })

    return {
      firstUserId,
      toggleFaultRecorderModal,
      addRecorderModal,
      runRecording,
      stopRecording,
      running,
      sendRecording,
      blob,
      resetRecordings
    }
  }
}
</script>

<style lang="scss" scoped>
.faultRecordingsContentWrapper{
  padding: 3% 18%;

  .recordingWrapper{
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: 600px;
    padding: 15px 15px;
    background-color: #ffffff;

    .recordingDetails{
      margin: 15px 0px;
    }

    .recordingAudioPlayer{
      margin: 15px;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 15px 5px;
  }
}
</style>
