import Fault from '@/models/fault_model'

export default class ProjectFiles {
  constructor (projectFile) {
    this.id = projectFile.id
    this.name = projectFile.name
    this.type = projectFile.type
    this.extension = projectFile.extension
    this.imageUrl = projectFile.imageUrl
    this.faults = this.parseFaults(projectFile.faults)
    this.lastUpdate = new Date().getTime()
  }

  parseFaults (faults) {
    const faultList = []
    for (const fault of faults) {
      faultList.push(new Fault(fault))
    }
    return faultList
  }
}
