<template>
  <ProjectCanvasContent
    :projectFile="projectFile"
    :constructionSiteId="constructionSiteId"
  />
</template>

<script>
import ProjectCanvasContent from '@/components/project_canvas/ProjectCanvasContent'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ProjectCanvas',
  props: {
    constructionSiteId: {
      required: true
    },
    projectFileId: {
      required: true
    }
  },
  components: {
    ProjectCanvasContent
  },
  setup (props) {
    const store = useStore()
    const projectFile = computed(() => store.state.projectFileStore.projectFiles?.find(item => item.id === props.projectFileId))

    return { projectFile }
  }
}
</script>

<style lang="scss" scoped>

</style>
