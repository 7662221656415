<template>
  <form @submit="onSubmit">
    <slot />

    <div class="buttonSection">
      <Button v-if="hasPrevious" type="button" @click="goToPrev" label="Powrót" class="p-button-outlined"></Button>
      <Button type="submit" :label="isLastStep ? 'Zarejestruj się' : 'Następny'"></Button>
      <Button @click="handleLoginClick" label="Zaloguj się" class="p-button-outlined"/>
    </div>
  </form>
</template>

<script>
import { useForm } from 'vee-validate'
import { ref, computed, provide } from 'vue'
import Button from 'primevue/button'
import router from '@/router'

export default {
  name: 'FormWizard',
  components: {
    Button
  },
  emits: ['next', 'submit'],
  props: {
    validationSchema: {
      type: null,
      required: true
    }
  },
  setup (props, { emit }) {
    const formData = ref({})
    const currentStepIdx = ref(0)

    const stepCounter = ref(0)
    provide('STEP_COUNTER', stepCounter)
    provide('CURRENT_STEP_INDEX', currentStepIdx)

    const isLastStep = computed(() => {
      return currentStepIdx.value === stepCounter.value - 1
    })

    const hasPrevious = computed(() => {
      return currentStepIdx.value > 0
    })

    const { resetForm, handleSubmit } = useForm({
      validationSchema: props.validationSchema
    })

    const onSubmit = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values
      }

      resetForm({
        values: {
          ...formData.value
        }
      })
      if (!isLastStep.value) {
        currentStepIdx.value++
        emit('next', formData.value)

        return
      }

      emit('submit', formData.value)
    })

    function goToPrev () {
      if (currentStepIdx.value === 0) {
        return
      }

      currentStepIdx.value--
    }

    function handleLoginClick () {
      router.push('/')
    }

    return {
      onSubmit,
      hasPrevious,
      isLastStep,
      goToPrev,
      handleLoginClick
    }
  }
}
</script>
<style lang="scss" scoped>
  .buttonSection {
    margin-top: 30px;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    button:nth-child(1) {
      margin-bottom: 10px;
    }

    button:nth-child(2) {
      margin-bottom: 30px;
    }
  }
</style>
