<template>
  <div class="documentCardWrapper p-d-flex p-d-column p-flex-wrap">
    <div class="documentDetails p-mr-3">
      <h3>{{ index + 1 + '.' }}</h3>
      <h3 style='word-break: break-word'>{{ document.name }}</h3>
    </div>
    <div class="documentActions p-d-flex p-d-column">
      <Button @click="handleDownloadDocument" :label="isDownloading ? 'Pobieranie' : 'Pobierz'" :icon="isDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'" iconPos="right" class="p-button-sm"
              :disabled="isDownloading"/>
      <Button @click="toggleRemoveDocumentModal" label="Usuń" icon="pi pi-trash" iconPos="right"
              class="p-button-sm p-button-danger"/>
    </div>
  </div>

  <ConfirmationModal
    text="Czy jesteś pewien, że chcesz usunąć ten dokument?"
    v-model:visible="removeDocumentModal"
    :noPressed="toggleRemoveDocumentModal"
    :yesPressed="submitRemoveDocumentModal"
  />

</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import Button from 'primevue/button'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import router from '@/router'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'ProjectFileContentDocumentsCard',
  components: {
    Button,
    ConfirmationModal
  },
  props: {
    document: {
      required: true
    },
    constructionSiteId: {
      required: true
    },
    index: {
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const toast = useToast()
    const removeDocumentModal = ref(false)
    const isDownloading = ref(false)

    const toggleRemoveDocumentModal = () => {
      removeDocumentModal.value = !removeDocumentModal.value
    }

    const submitRemoveDocumentModal = () => {
      toggleRemoveDocumentModal()

      store.dispatch('projectFileStore/deleteDocument', {
        constructionSiteId: props.constructionSiteId,
        documentId: props.document.id
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Usuwanie documentu',
            detail: 'Pomyślnie usunięto document.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Usuwanie dokumentu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const handleDownloadDocument = async () => {
      isDownloading.value = true

      await store.dispatch('projectFileStore/downloadDocument', {
        constructionSiteId: props.constructionSiteId,
        documentId: props.document.id,
        documentName: props.document.name
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Pobieranie documentu',
            detail: 'Pomyślnie pobrano document.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Pobieranie dokumentu',
            detail: error.message,
            life: 3000
          })
        }
      )

      isDownloading.value = false
    }

    return {
      removeDocumentModal,
      toggleRemoveDocumentModal,
      submitRemoveDocumentModal,
      handleDownloadDocument,
      isDownloading
    }
  }
}
</script>

<style lang="scss" scoped>
.documentCardWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .documentDetails {
    display: flex;
    align-items: center;

    h3 {
      font-weight: 600;
    }

    h3:nth-child(1) {
      margin-right: 25px;
    }
  }

  .documentActions {
    Button:nth-child(1) {
      margin-right: 15px;
    }
  }

  @media screen and (max-width: 1000px) {
    display: block;

    .documentActions {
      margin-top: 15px;
    }
  }
}
</style>
