<template>
    <div v-if="isMobile">
      <MobileCard/>
    </div>
    <div v-else>
      <div class="loginWrapper">
        <div class="loginBox">
          <img :src="require('@/assets/logo.jpg')" alt="logo"/>
          <Form @submit="handlePasswordReset">
            <div class='p-mt-2 p-mb-4 p-text-center'>
              <p>Na podany adres email został wysłany kod weryfikacyjny. Wprowadź ten kod w poniższe pole</p>
            </div>
            <div class="labeledInputPadding">
              <LabeledInput
                name="code_input"
                type="text"
                rules="required"
                v-model:modelValue="codeValue"
                placeholder="Wprowadź kod weryfikacyjny"
              />
            </div>
            <div class='p-mt-5 p-mb-4 p-text-center'>
              <p>Wprowadź nowe hasło. Hasło musi zawierać minimum 8 znaków, 1 wielką literę, 1 cyfrę, 1 znak specjalny</p>
            </div>
            <div class="labeledInputPadding">
              <LabeledInput
                name="password_reset"
                type="password"
                rules="required|password"
                v-model:modelValue="passwordValue"
                placeholder="Wprowadź nowe hasło"
              />
            </div>

            <div class="buttonSection">
              <Button label="Dalej" type="submit"/>
              <Button label="Wróć" @click='backToLogin()' class="p-button-outlined"/>
            </div>
          </Form>
        </div>
      </div>

    </div>
  </template>

<script>
import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import router from '@/router'
import { Form } from 'vee-validate'
import LabeledInput from '@/components/LabeledInput'
import Button from 'primevue/button'
import { isOnMobile } from '@/utils/util'
import MobileCard from '@/components/MobileCard'

export default {
  name: 'LoginComponent',
  components: {
    Form,
    LabeledInput,
    Button,
    MobileCard
  },
  setup () {
    const store = useStore()
    const codeValue = ref('')
    const passwordValue = ref('')
    const isLoggedIn = computed(() => store.state.authStore.status.loggedIn)
    const isMobile = computed(() => isOnMobile())
    const reminderStatus = ref(false)
    const toast = useToast()

    onMounted(() => {
      if (isLoggedIn.value) router.push('/construction-site')
    })

    const handlePasswordReset = async () => {
      await store.dispatch('authStore/handleResetPassword', {
        codeValue: codeValue.value,
        passwordValue: passwordValue.value
      }).then(
        async (response) => {
          if (response) {
            toast.add({
              severity: 'warn',
              summary: 'Przypominanie hasła',
              detail: 'Wystąpił błąd. Spróbuj ponownie',
              life: 3000
            })
          } else {
            toast.add({
              severity: 'success',
              summary: 'Sukces!',
              detail: 'Zmiana hasła została zakończona sukcesem. Za chwilę zostaniesz przekierowany na stronę logowania',
              life: 3000
            })
            setTimeout(() => {
              router.push('/')
            }, 5000)
          }
        }
      )
    }
    const backToLogin = () => {
      router.push('/')
    }

    return {
      passwordValue,
      codeValue,
      isMobile,
      reminderStatus,
      backToLogin,
      handlePasswordReset
    }
  }
}
</script>
  <style lang="scss" scoped>
  .loginWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loginBox {
      width: 400px;
      background-color: white;
      padding: 25px 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 15px;
      -webkit-box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.08);
      box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.08);

      .labeledInputPadding {
        padding: 0 20px;
      }

      .password_reminder {
        text-align: center;
        color: #6c757d;
        :hover {
          color: #000;
        }
      }

      img {
        width: 150px;
        align-self: center;
      }

      .buttonSection {
        margin-top: 45px;
        display: flex;
        flex-direction: column;

        button:nth-child(1) {
          margin-bottom: 15px;
        }
      }
    }
  }
  </style>
