<template>
  <div class="constructionSiteCardWrapper p-shadow-1">
    <div class="image">
      <Skeleton borderRadius="25px" height="200px"/>
    </div>

    <div class="details">
      <Skeleton width="70%" height="20px"/>
      <br/>
      <Skeleton width="40%" height="12px"/>
    </div>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton'

export default {
  name: 'SkeletonCard',
  components: { Skeleton }
}
</script>

<style lang="scss" scoped>
.constructionSiteCardWrapper {
  height: 320px;
  width: 100%;
  background: white;
  border-radius: 25px;

  .image {
    position: relative;
    height: 200px;
    -webkit-border-top-left-radius: 25px;
    -webkit-border-top-right-radius: 25px;
    -moz-border-radius-topleft: 25px;
    -moz-border-radius-topright: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  .details {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
