import AuthService from '@/services/auth.service'
import User from '@/models/user_model'
import SecureStorage from '@/services/SecureStorage'

const ls = SecureStorage.get()
const user = ls.get('user')

const initialState =
  user
    ? {
        status: { loggedIn: true }, user: user, userDetails: null
      }
    : {
        status: { loggedIn: false }, user: null, userDetails: null
      }

const state = initialState

const getters = {
  getUserDetails: (state) => state.userDetails
}

const actions = {
  async login ({ commit }, user) {
    return AuthService.login(user).then(
      async user => {
        commit('loginSuccess', user)
        return Promise.resolve(user)
      },
      error => {
        commit('loginFailure')
        return Promise.reject(error.response.data)
      }
    )
  },
  async fetchUserDetails ({ commit }) {
    return AuthService.fetchDetails().then(
      user => {
        commit('fetchUserDetailsSuccess', user)
        return Promise.resolve()
      },
      error => {
        commit('fetchUserDetailsFailure')
        return Promise.reject(error.response.data)
      }
    )
  },
  async logout ({ commit, dispatch }) {
    await dispatch('constructionSiteStore/deleteStore', '', { root: true })
    await dispatch('projectFileStore/deleteStore', '', { root: true })
    await AuthService.logout()
    ls.resetAllKeys()
    commit('logout')
  },
  async register ({ commit }, user) {
    return AuthService.register(user).then(
      response => {
        commit('registerSuccess')
        return Promise.resolve(response.data)
      },
      error => {
        commit('registerFailure')
        return Promise.reject(error.response.data)
      }
    )
  },
  async sendEmailForPasswordReminder ({ commit, dispatch }, payload) {
    try {
      await AuthService.sendEmailForPasswordReminder(payload.value)
    } catch (error) {
      return Promise.resolve(error)
    }
  },
  async handleResetPassword ({ commit, dispatch }, payload) {
    try {
      await AuthService.resetPassword(payload)
      Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  loginSuccess (state, user) {
    state.status = { loggedIn: true }
    state.user = user
  },
  loginFailure (state) {
    state.status = {}
    state.user = null
  },
  fetchUserDetailsSuccess (state, user) {
    const userDetails = new User(user)
    state.userDetails = userDetails
  },
  fetchUserDetailsFailure (state) {
    state.userDetails = null
  },
  async logout (state) {
    state.status = {}
    state.user = null
    state = {}
  },
  registerSuccess (state) {
    state.status = {}
  },
  registerFailure (state) {
    state.status = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
