import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import ConstructionSite from '../views/ConstructionSite.vue'
import ProjectFile from '@/views/ProjectFile'
import Register from '@/views/Register'
import ProjectFileContent from '@/components/project_file/ProjectFileContent'
import ProjectFileContentFaults from '@/components/project_file/ProjectFileContentFaults'
import ProjectFileContentDocuments from '@/components/project_file/ProjectFileContentDocuments'
import store from '@/store'
import ProjectCanvas from '@/views/ProjectCanvas'
import Fault from '@/views/Fault'
import FaultContentRecordings from '@/components/fault/FaultContentRecordings'
import FaultContentMessages from '@/components/fault/FaultContentMessages'
import FaultContentPhotos from '@/components/fault/FaultContentPhotos'
import FaultContent from '@/components/fault/FaultContent'
import PasswordReminderView from '@/views/PasswordReminderView'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: 'EMS - Logowanie',
      routeName: 'Logowanie',
      metaTags: [
        {
          name: 'description',
          content: 'Strona domowa aplikacji EMS.'
        },
        {
          property: 'og:description',
          content: 'Strona domowa aplikacji EMS.'
        }
      ]
    }
  },
  {
    path: '/register',
    name: 'Register',
    props: true,
    component: Register,
    meta: {
      title: 'EMS - Rejestracja',
      routeName: 'Rejestracja w aplikacji EMS',
      metaTags: [
        {
          name: 'description',
          content: 'Rejestracja do aplikacji EMS.'
        },
        {
          property: 'og:description',
          content: 'Rejestracja do aplikacji EMS.'
        }
      ]
    }
  },
  {
    path: '/construction-site',
    name: 'ConstructionSite',
    component: ConstructionSite,
    meta: {
      title: 'EMS - Obiekty użytkownika',
      routeName: 'Widok obiektów w systemie EMS',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera obiekty użytkownika.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera obiekty użytkownika.'
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      const hasPermission = store.state.authStore.status.loggedIn
      const user = store.state.authStore.status.user
      if (user !== null && hasPermission) {
        next()
      } else {
        next({ path: '/' })
      }
    }
  },
  {
    path: '/construction-site/:constructionSiteId',
    name: 'ProjectFile',
    component: ProjectFile,
    props: true,
    meta: {
      title: 'EMS - Projekty użytkownika',
      routeName: 'Widok projektów w systemie EMS',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera projekty użytkownika.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera projekty użytkownika.'
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      const hasPermission = store.state.authStore.status.loggedIn
      const user = store.state.authStore.status.user
      if (user !== null && hasPermission) {
        next()
      } else {
        next({ path: '/' })
      }
    },
    children: [
      {
        path: '',
        props: true,
        component: ProjectFileContent,
        name: 'ProjectFileContent',
        meta: {
          title: 'EMS - Projekty',
          routeName: 'Projekty',
          metaTags: [
            {
              name: 'description',
              content: 'Strona zawiera projekty użytkownika.'
            },
            {
              property: 'og:description',
              content: 'Strona zawiera projekty użytkownika.'
            }
          ]
        }
      },
      {
        path: 'faults',
        props: true,
        component: ProjectFileContentFaults,
        meta: {
          title: 'EMS - Usterki',
          routeName: 'Usterki obiektu',
          metaTags: [
            {
              name: 'description',
              content: 'Strona zawiera usterki obiektu.'
            },
            {
              property: 'og:description',
              content: 'Strona zawiera usterki obiektu.'
            }
          ]
        },
        name: 'ProjectFileContentFaults'
      },
      {
        path: 'documents',
        props: true,
        meta: {
          title: 'EMS - Dokumenty',
          routeName: 'Dokumenty dostępne w obiekcie',
          metaTags: [
            {
              name: 'description',
              content: 'Strona zawiera dokumenty obiektu.'
            },
            {
              property: 'og:description',
              content: 'Strona zawiera dokumenty obiektu.'
            }
          ]
        },
        component: ProjectFileContentDocuments,
        name: 'ProjectFileContentDocuments'
      }
    ]
  },
  {
    path: '/construction-site/:constructionSiteId/project-file/:projectFileId',
    props: true,
    meta: {
      title: 'EMS - Projekt z usterkami',
      routeName: 'Projekt z usterkami',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera projekt z usterkami.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera projekt z usterkami.'
        }
      ]
    },
    component: ProjectCanvas,
    name: 'ProjectCanvas'
  },
  {
    path: '/fault/:faultId',
    props: (route) => ({ faultId: route.query.faultId, constructionSiteId: route.query.constructionSiteId, projectFileId: route.query.projectFileId }),
    component: Fault,
    name: 'Fault',
    meta: {
      title: 'EMS - Userka',
      routeName: 'Usterka',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera usterkę.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera usterkę.'
        }
      ]
    },
    children: [
      {
        path: '',
        props: true,
        component: FaultContent,
        meta: {
          title: 'EMS - Szczegóły usterki',
          routeName: 'Szczegóły usterki',
          metaTags: [
            {
              name: 'description',
              content: 'Strona zawiera szczegóły usterki.'
            },
            {
              property: 'og:description',
              content: 'Strona zawiera szczegóły usterki.'
            }
          ]
        },
        name: 'FaultContent'
      },
      {
        path: 'photos',
        props: true,
        meta: {
          title: 'EMS - Zdjęcia usterki',
          routeName: 'Zdjęcia usterki',
          metaTags: [
            {
              name: 'description',
              content: 'Strona zawiera zdjęcia usterki.'
            },
            {
              property: 'og:description',
              content: 'Strona zawiera zdjęcia usterki.'
            }
          ]
        },
        component: FaultContentPhotos,
        name: 'FaultContentPhotos'
      },
      {
        path: 'recordings',
        props: true,
        meta: {
          title: 'EMS - Nagrania usterki',
          routeName: 'Nagrania usterki',
          metaTags: [
            {
              name: 'description',
              content: 'Strona zawiera nagrania usterki.'
            },
            {
              property: 'og:description',
              content: 'Strona zawiera nagrania usterki.'
            }
          ]
        },
        component: FaultContentRecordings,
        name: 'FaultContentRecordings'
      },
      {
        path: 'messages',
        props: true,
        meta: {
          title: 'EMS - Czat usterki',
          routeName: 'Czat usterki',
          metaTags: [
            {
              name: 'description',
              content: 'Strona zawiera czat usterki.'
            },
            {
              property: 'og:description',
              content: 'Strona zawiera czat usterki.'
            }
          ]
        },
        component: FaultContentMessages,
        name: 'FaultContentMessages'
      }
    ]
  },
  {
    path: '/password_reminder_view',
    component: PasswordReminderView,
    name: 'PasswordReminderView',
    meta: {
      title: 'EMS - Przypomnij hasło',
      routeName: 'Przypomnij hasło',
      metaTags: [
        {
          name: 'description',
          content: 'Zmiana hasła w aplikacji EMS.'
        },
        {
          property: 'og:description',
          content: 'Zmiana hasła w aplikacji EMS.'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    .forEach((tag) => document.head.appendChild(tag))

  next()
})

export default router
