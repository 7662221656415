<template>
  <div class="p-col-3 photoCard p-shadow-1">
    <div class="deleteImage" @click.prevent="toggleRemoveFaultPhotoModal">
      <i class="pi pi-times"/>
    </div>
    <img :src="image.imageUrl" :alt="image.alt" @click="faultPhotoClick"/>
  </div>

  <ConfirmationModal
    text="Czy jesteś pewien, że chcesz usunąć to zdjęcie?"
    v-model:visible="removePhotoModal"
    :noPressed="toggleRemoveFaultPhotoModal"
    :yesPressed="submitRemoveFaultPhoto"
  />
</template>

<script>
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import { ref } from 'vue'
export default {
  name: 'FaultContentCard',
  props: {
    image: {
      required: true
    },
    index: {
      required: true
    }
  },
  components: {
    ConfirmationModal
  },
  emits: ['submitRemoveFaultPhotoModal', 'imageClick'],
  setup (props, { emit }) {
    const removePhotoModal = ref(false)

    const toggleRemoveFaultPhotoModal = () => {
      removePhotoModal.value = !removePhotoModal.value
    }
    const submitRemoveFaultPhoto = () => {
      toggleRemoveFaultPhotoModal()
      emit('submitRemoveFaultPhotoModal', props.index, props.image)
    }

    const faultPhotoClick = () => {
      emit('imageClick', props.index)
    }

    return { submitRemoveFaultPhoto, faultPhotoClick, toggleRemoveFaultPhotoModal, removePhotoModal }
  }
}
</script>

<style scoped lang="scss">
.photoCard {
  background-color: white;
  display: flex;
  position: relative;
  width: 260px;
  height: auto;
  justify-content: center;
  margin: 0 30px 30px 0;
  border-radius: 3px;

  .deleteImage {
    position: absolute;
    height: 35px;
    width: 35px;
    right: 0;
    top: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    z-index: 999;

    i {
      color: red;
      font-size: 20px;
    }
  }

  img {
    object-fit: cover;
    cursor: pointer;
    padding: 10px;
    max-width: 250px;
    max-height: 250px;
  }
}
</style>
