<template>
  <div class="registerWrapper">
    <RegisterUserContent :currentStep="currentStepNumber"
                         @nextStep="handleNextStep"
                         @previousStep="handlePreviousStep"/>
  </div>
</template>

<script>
import RegisterUserContent from '@/components/RegisterUserContent'
import { useToast } from 'primevue/usetoast'
import { provide } from 'vue'

export default {
  name: 'RegisterComponent',
  components: {
    RegisterUserContent
  },
  methods: {
    handleNextStep: function () {
      this.currentStepNumber = 1
    },
    handlePreviousStep: function () {
      this.currentStepNumber = 0
    }
  },
  data () {
    return {
      currentStepNumber: 0
    }
  },
  setup () {
    const toast = useToast()
    const updateToast = (severity, summary, detail) => {
      toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        life: 3000
      })
    }

    provide('updateToast', updateToast)
    return { updateToast }
  }
}
</script>

<style lang="scss" scoped>
.registerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
