<template>
  <div class="faultCard p-shadow-1">
    <p>{{ fault.name }}</p>
    <p class='p-mt-1'>{{ fault.description }}</p>
    <div class="p-d-flex p-flex-row">
      <p class='p-mt-1'>Przypisano do: </p>
      <p class='p-mt-1' v-if="fault.UserId !== null">
        &nbsp; {{ assignedUser }} </p>
      <p class='p-mt-1' v-else> &nbsp; Brak </p>
    </div>
    <div v-if="fault.isPaid" class="faultPaidRow">
      <p class='p-mt-1'>Koszt naprawy: {{ fault.price }} PLN</p>
      <div class="p-d-flex p-flex-row">
        <p class='p-mt-1'>Akceptacja właściciela: </p>
        <p class='p-mt-1 p-ml-1' v-if="fault.ownerAccepted"> Tak </p>
        <p class='p-mt-1 p-ml-1' v-else> Nie </p>
      </div>
      <div class="p-d-flex p-flex-row">
        <p class='p-mt-1'>Akceptacja wykonawcy: </p>
        <p class='p-mt-1 p-ml-1' v-if="fault.workerAccepted"> Tak </p>
        <p class='p-mt-1 p-ml-1' v-else> Nie </p>
      </div>
    </div>
    <div class="faultProgressRow">
      <div v-if="fault.faultPriority === 'FaultPriority.high'" class="faultRow">
        <p>Priorytet: </p>
        <i class="pi pi-circle-on high"></i>
        <p style="color: #ff0000"> Wysoki </p>
      </div>
      <div v-if="fault.faultPriority === 'FaultPriority.medium'" class="faultRow">
        <p>Priorytet: </p>
        <i class="pi pi-circle-on medium"></i>
        <p style="color: #FF9800"> Średni </p>
      </div>
      <div v-if="fault.faultPriority === 'FaultPriority.low'" class="faultRow">
        <p>Priorytet: </p>
        <i class="pi pi-circle-on low"></i>
        <p style="color: #4caf50"> Niski </p>
      </div>
      <div v-if="fault.faultProgressType === 'FaultProgressType.todo'" class="faultRow">
        <p>Status: </p>
        <i class="pi pi-briefcase high"></i>
        <p style="color: #ff0000"> Do naprawy </p>
      </div>
      <div v-if="fault.faultProgressType === 'FaultProgressType.inProgress'" class="faultRow">
        <p>Status: </p>
        <i class="pi pi-briefcase medium"></i>
        <p style="color: #FF9800"> W trakcie </p>
      </div>
      <div v-if="fault.faultProgressType === 'FaultProgressType.done'" class="faultRow">
        <p>Status: </p>
        <i class="pi pi-briefcase low"></i>
        <p style="color: #4caf50"> Naprawiona </p>
      </div>
    </div>
    <div class="faultButtonsRow">
      <div v-if="constructionSite[0].role === 'Owner'">
        <Button v-if="fault.isArchived === false" label="Dodaj do archiwum / Usuń" type="button" class="p-button-outlined" @click="toggleFaultArchiveModal"/>
        <Button v-if="fault.isArchived" label="Przywróć z archiwum" type="button" class="p-button-outlined" @click="toggleFaultArchivedModal"/>
      </div>
      <Button label="Szczegóły" type="button" class="p-button-raised" @click="handleNavigationToFault"/>
    </div>
  </div>
<!--  <ConfirmationModal-->
<!--    :text="'Czy jesteś pewien, że chcesz dodać usterkę o nazwie ' + fault.name + ' do archiwum?'"-->
<!--    v-model:visible="removeFaultArchiveModal"-->
<!--    :noPressed="toggleFaultArchiveModal"-->
<!--    :yesPressed="submitFaultArchiveModal"-->
<!--  />-->
  <ArchiveDeleteModal
    v-model:visible="removeFaultArchiveModal"
    :toggle="toggleFaultArchiveModal"
    :submit="submitFaultArchiveModal"
    :fault="fault"
    :assignedUser="assignedUser"
    ></ArchiveDeleteModal>
  <ConfirmationModal
    :text="'Czy jesteś pewien, że chcesz przywrócić usterkę o nazwie ' + fault.name + ' z archiwum?'"
    v-model:visible="removeFaultArchivedModal"
    :noPressed="toggleFaultArchivedModal"
    :yesPressed="submitFaultArchivedModal"
  />
</template>
<script>
import Button from 'primevue/button'
import { computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import router from '@/router'
import ArchiveDeleteModal from '@/components/modals/ArchiveDeleteModal'

export default {
  name: 'FaultsListTileItem',
  props: {
    fault: {
      type: Object,
      required: true
    },
    users: {
      type: Object
    },
    constructionSiteId: {
      required: true
    }
  },
  components: {
    ArchiveDeleteModal,
    Button,
    ConfirmationModal
  },
  setup (props) {
    const store = useStore()
    const removeFaultArchivedModal = ref(false)
    const removeFaultArchiveModal = ref(false)
    const updateToast = inject('updateToast')
    const updateFaults = inject('updateFaults')

    const constructionSite = computed(() => store.state.constructionSiteStore.constructionSites.filter(constuctionSite => constuctionSite.id === props.constructionSiteId))

    const toggleFaultArchivedModal = () => {
      removeFaultArchivedModal.value = !removeFaultArchivedModal.value
    }

    const toggleFaultArchiveModal = () => {
      removeFaultArchiveModal.value = !removeFaultArchiveModal.value
    }

    const submitFaultArchiveModal = (type) => {
      if (type === 'Archive') {
        const updatedFault = JSON.parse(JSON.stringify(props.fault))
        updatedFault.isArchived = true
        updatedFault.workflows = []

        store.dispatch('projectFileStore/updateFault', {
          constructionSiteId: props.constructionSiteId,
          projectFileId: props.fault.ProjectFileId,
          fault: updatedFault
        }).then(
          async () => {
            updateFaults()
            await updateToast('success', 'Archiwizacja', 'Pomyślnie zarchiwizowano usterkę.')
          },
          async (error) => {
            if (error.status === 401) {
              await router.push('/')
            }
            await updateToast('warn', 'Wystąpił błąd podczas archiwizacji', error.message)
          }
        )
      } else if (type === 'Delete') {
        const updatedFault = JSON.parse(JSON.stringify(props.fault))
        updatedFault.workflows = []

        store.dispatch('projectFileStore/removeFault', {
          constructionSiteId: props.constructionSiteId,
          projectFileId: props.fault.ProjectFileId,
          fault: updatedFault
        }).then(
          async () => {
            updateFaults()
            await updateToast('success', 'Archiwizacja', 'Pomyślnie usunięto usterkę.')
          },
          async (error) => {
            if (error.status === 401) {
              await router.push('/')
            }
            await updateToast('warn', 'Wystąpił błąd podczas usuwania', error.message)
          }
        )
      }
      toggleFaultArchiveModal()
    }

    const submitFaultArchivedModal = () => {
      const updatedFault = JSON.parse(JSON.stringify(props.fault))
      updatedFault.isArchived = false
      updatedFault.workflows = []

      store.dispatch('projectFileStore/updateFault', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.fault.ProjectFileId,
        fault: updatedFault
      }).then(
        async () => {
          updateFaults()
          await updateToast('success', 'Archiwizacja', 'Pomyślnie przywrócono usterkę z archiwum.')
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          await updateToast('warn', 'Archiwizacja', error.message)
        }
      )
      toggleFaultArchivedModal()
    }

    const assignedUser = computed(() => {
      for (let i = 0; i < props.users.length; i++) {
        if (props.users[i].id === props.fault.UserId) {
          return props.users[i].name + ' ' + props.users[i].surname
        }
      }
      return 'Brak danych'
    })

    const handleNavigationToFault = () => {
      router.push({
        path: '/fault/' + props.fault.id,
        query: { faultId: props.fault.id, constructionSiteId: props.constructionSiteId, projectFileId: props.fault.ProjectFileId }
      })
    }

    return {
      assignedUser,
      toggleFaultArchivedModal,
      removeFaultArchivedModal,
      submitFaultArchivedModal,
      toggleFaultArchiveModal,
      removeFaultArchiveModal,
      submitFaultArchiveModal,
      handleNavigationToFault,
      constructionSite
    }
  }
}
</script>
<style lang="scss" scoped>
.faultCard {
  background-color: white;
  padding: 25px 60px;
  margin: 25px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;

  .faultProgressRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 15px 0;

    .high {
      color: #ff0000;
    }

    .medium {
      color: #FF9800;
    }

    .low {
      color: #4caf50;
    }

    .faultRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 190px;
    }

  }

  .faultButtonsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 15px 0;

    Button {
      width: 300px;
      height: 30px;
      padding: 0 15px;

    }
  }
}

@media screen and (max-width: 767px) {
  .faultCard {
    justify-content: center;
  }
}
</style>
