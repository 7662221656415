export default class User {
  constructor (user) {
    this.id = user.id
    this.name = user.name
    this.surname = user.surname
    this.email = user.email
    this.password = user.password
    this.company = user.company
    this.department = user.department
    this.position = user.position
    this.city = user.city
    this.street = user.street
    this.addressNumber = user.addressNumber
    this.zipCode = user.zipCode
    this.role = user.role
  }
}
