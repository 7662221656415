<template>
  <router-view v-if="!isLoading"/>
  <Toast position="bottom-right" />
</template>
<script>
import Toast from 'primevue/toast'
import { onMounted, ref } from 'vue'
import router from '@/router'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
export default {
  name: 'App',
  components: {
    Toast
  },
  setup () {
    const store = useStore()
    const toast = useToast()
    const isLoading = ref(true)

    onMounted(async () => {
      await store.dispatch('authStore/fetchUserDetails').then(
        () => {
          isLoading.value = false
        },
        async () => {
          await store.dispatch('authStore/logout')
          await router.push('/')
          toast.add({
            severity: 'warn',
            summary: 'Sesja utraciła ważność.',
            detail: 'Sesja utraciła ważność, zaloguj się ponownie do systemu!',
            life: 10000
          })
          isLoading.value = false
        }
      )
    })

    return { isLoading }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  overflow-x: hidden;
  background-color: #f8f8f8;
}
</style>
