import authContentTypeHeader from '@/services/auth_content_type_header'
import authOctetHeader from '@/services/auth_octet_header'
import authUploadFile from '@/services/auth_upload_file'
import instance from '@/services/api'

class FaultService {
  async add (constructionSiteId, projectFileId, fault) {
    try {
      await instance.post('/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults', {
        id: fault.id,
        name: fault.name,
        description: fault.description,
        faultCategory: fault.faultCategory,
        faultProgressType: fault.faultProgressType,
        faultPriority: fault.faultPriority,
        markerPositionDx: fault.markerPositionDx,
        markerPositionDy: fault.markerPositionDy,
        price: fault.price,
        isPaid: fault.isPaid,
        deadline: fault.deadline,
        emails: fault.emails,
        ownerAccepted: fault.ownerAccepted,
        workerAccepted: fault.workerAccepted,
        isArchived: fault.isArchived,
        workflows: fault.workflows,
        lastUpdate: fault.lastUpdate
      }, { headers: authContentTypeHeader() })

      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async update (constructionSiteId, projectFileId, fault) {
    try {
      await instance.put('/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults', {
        id: fault.id,
        name: fault.name,
        description: fault.description,
        faultCategory: fault.faultCategory,
        faultProgressType: fault.faultProgressType,
        faultPriority: fault.faultPriority,
        markerPositionDx: fault.markerPositionDx,
        markerPositionDy: fault.markerPositionDy,
        price: fault.price,
        isPaid: fault.isPaid,
        deadline: fault.deadline,
        emails: fault.emails,
        ownerAccepted: fault.ownerAccepted,
        workerAccepted: fault.workerAccepted,
        isArchived: fault.isArchived,
        workflows: fault.workflows,
        UserId: fault.UserId,
        lastUpdate: fault.lastUpdate
      }, { headers: authContentTypeHeader() })

      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async delete (constructionSiteId, projectFileId, faultId) {
    try {
      await instance.delete('/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId, { headers: authContentTypeHeader() })

      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getImage (constructionSiteId, projectFileId, faultId, imageId) {
    try {
      const response = await instance.get('/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/images/' + imageId, { headers: authOctetHeader(), responseType: 'blob' })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async uploadImage (constructionSiteId, projectFileId, faultId, formData) {
    try {
      const response = await instance.post('/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/images', formData, { headers: authUploadFile() })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async removeImage (constructionSiteId, projectFileId, faultId, faultPhotoId) {
    try {
      const response = await instance.delete('/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/images/' + faultPhotoId, { headers: authUploadFile() })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async uploadRecording (constructionSiteId, projectFileId, faultId, formData) {
    try {
      const response = await instance.post('/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/recordings', formData, { headers: authUploadFile() })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new FaultService()
