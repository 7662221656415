<template>
  <div class="faultContentWrapper">
    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <div class="categorySection">
          <h3>Kategoria</h3>
          <Dropdown v-model="selectedCategory" :options="categoryOptions" optionLabel="name" :disabled="isLoading || constructionSite[0].role === 'Worker'"/>
        </div>
        <div class="nameAndDescriptionSection p-shadow-1">
          <div v-if="!isLoading">
            <div v-if='constructionSite[0].role === "Owner"'>
              <h4 @click="toggleFaultNameModal">{{ updatedFault?.name }}</h4>
              <p @click="toggleFaultDescriptionModal">{{ updatedFault?.description }}</p>
            </div>
            <div v-else>
              <h4>{{ updatedFault?.name }}</h4>
              <p>{{ updatedFault?.description }}</p>
            </div>
          </div>
          <div v-else>
            <Skeleton width="50%" height="15px"/>
            <br/>
            <Skeleton width="90%" height="15px"/>
          </div>
        </div>
        <div class="assignedSection">
          <h3>Przypisano do</h3>
          <CheckboxList
            v-if="!isLoading"
            :items="assignedUsersCheckbox"
            v-model:modelValue="assignedUserValueCheckbox"
            :disabled="constructionSite[0].role === 'Worker'"
          />
          <div v-else class="skeletonBox p-shadow-1">
            <Skeleton width="70%" height="15px"/>
            <br/>
            <Skeleton width="90%" height="15px"/>
          </div>
        </div>
        <div class="subscriptionList">
          <h3>Lista powiadomień</h3>
          <div v-if="!isLoading">
            <Form @submit="submitAddNewEmail">
              <div class="p-d-flex p-flex-row p-jc-between p-ai-center newEmailRow">
                <LabeledInput
                  name="assign_user_email_input"
                  rules="required|email"
                  type="text"
                  v-model:modelValue="newEmailValue"
                  placeholder="Wprowadź adres email"
                />
                <Button class="p-button-raised " label="Dodaj" type="submit"/>
              </div>
            </Form>
            <div v-if="subscriptionList.length === 0">
              <div class="subscriptionItem p-shadow-1 p-d-flex p-ai-center p-jc-between">
                <p> Brak adresów na liście</p>
              </div>
            </div>
            <div v-else v-for="(item, index) in subscriptionList" :key="item.id">
              <div class="subscriptionItem p-shadow-1 p-d-flex p-ai-center p-jc-between">
                <p> {{ item.email }}</p>
                <i class="pi pi-trash" @click.prevent="removeEmailListItem(index)"/>
              </div>
            </div>
          </div>
          <div v-else class="skeletonBox p-shadow-1">
            <Skeleton width="90%" height="15px"/>
          </div>
        </div>
        <div class="historySection">
          <h3>Historia zmian</h3>
          <div class="box p-shadow-1">
            <div v-if="!isLoading">
              <div class="workflow" v-for="workflow in workflows" :key="workflow">
                <h6>{{ parseDateSinceEpoch(workflow.date) }}</h6>
                <p style="white-space: pre-wrap">{{ workflow.workflow }}</p>
              </div>
            </div>
            <div v-else class="workflow">
              <Skeleton width="70%" height="15px"/>
              <br/>
              <Skeleton width="90%" height="15px"/>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6">
        <div class="p-d-flex p-dir-row p-jc-between p-ai-center p-mb-3">
          <h3>Lokalizacja usterki</h3>
          <Button label="Zmień" @click="changeLocalization" icon="pi pi-map-marker" class='p-button-outlined' :disabled="constructionSite[0].role === 'Worker'" />
        </div>
        <div class="realisationData p-d-flex p-dir-row p-jc-between p-ai-center">
          <h3>Data realizacji</h3>
          <Calendar v-model="realisationDate" :showButtonBar="true" :disabled="constructionSite[0].role === 'Worker'" />
        </div>
        <div class="priorityAndStatusSection p-shadow-1">
          <h3>Priorytet</h3>
          <div class="priority">
            <SelectButton v-if="!isLoading" v-model="priorityValue" :options="priorityOptions" optionLabel="name" :disabled="constructionSite[0].role === 'Worker'">
              <template #option="slotProps">
                <div class="priorityCard">
                  <div class="circle" :style="{backgroundColor: slotProps.option.color}"></div>
                  <p>{{ slotProps.option.name }}</p>
                </div>
              </template>
            </SelectButton>
            <Skeleton v-else width="100%" height="60px"/>
          </div>
          <h3>Status</h3>
          <div class="status">
            <div v-if="!isLoading">
              <div class="slider">
                <Slider v-model="sliderValue" :step="50"/>
              </div>
              <div class="labels">
                <p>Do naprawy</p>
                <p>W trakcie</p>
                <p>Naprawiona</p>
              </div>
            </div>
            <div v-else>
              <Skeleton width="100%" height="60px"/>
            </div>
          </div>
        </div>
        <div class="payableFaultSection p-shadow-1">
          <h3>Płatność</h3>
          <div v-if="!isLoading">
            <div class="isFaultPayable">
              <p>Czy usterka jest płatna ?</p>
              <Checkbox v-model="isFaultPayable" :binary="true"/>
            </div>
            <div v-if="isFaultPayable" class="afterIsFaultPayable">

              <CheckboxButton v-model:modelValue="isOwnerAccepted" text="Akceptacja właściciela" :disabled="constructionSite[0].role !== 'Owner'"/>
              <div class="priceBox">
                <InputNumber id="currency-pln" v-model="priceValue" mode="currency" currency="PLN" currencyDisplay="code" locale="pl-PL" />
              </div>
              <CheckboxButton v-model:modelValue="isWorkerAccepted" text="Akceptacja wykonawcy" :disabled="loggedUser.id !== fault?.UserId"/>
            </div>
          </div>
          <div v-else>
            <Skeleton width="100%" height="21px"/>
          </div>
        </div>
        <Button @click="handleSaveFault" label="Zapisz usterkę" icon="pi pi-save" class="p-button-md saveButton" :disabled="isLoading"/>
      </div>
    </div>
  </div>

  <BasicFormModal
    v-bind="$attrs"
    v-model:modelValue="faultNameValue"
    v-model:visible="faultNameModal"
    :submit="submitFaultNameModal"
    :toggle="toggleFaultNameModal"
    header="Zmiana nazwy usterki"
    placeholder="Nazwa usterki"
  />

  <BasicFormModal
    v-bind="$attrs"
    v-model:modelValue="faultDescriptionValue"
    v-model:visible="faultDescriptionModal"
    :submit="submitFaultDescriptionModal"
    :toggle="toggleFaultDescriptionModal"
    header="Zmiana opisu usterki"
    placeholder="Opis usterki"
  />
</template>

<script>
import Dropdown from 'primevue/dropdown'
import CheckboxList from '@/components/fault/CheckboxList'
import SelectButton from 'primevue/selectbutton'
import Slider from 'primevue/slider'
import Skeleton from 'primevue/skeleton'
import Checkbox from 'primevue/checkbox'
import { ref, onMounted, onBeforeUpdate, computed } from 'vue'
import { useStore } from 'vuex'
import { createId, parseDateSinceEpoch } from '@/utils/util'
import CheckboxButton from '@/components/CheckboxButton'
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import BasicFormModal from '@/components/modals/BasicFormModal'
import Fault from '@/models/fault_model'
import { Form } from 'vee-validate'
import LabeledInput from '@/components/LabeledInput'
import Workflow from '@/models/workflow_model'
import { useToast } from 'primevue/usetoast'
import router from '@/router'

export default {
  name: 'FaultContent',
  components: {
    BasicFormModal,
    CheckboxButton,
    Dropdown,
    CheckboxList,
    Form,
    LabeledInput,
    Slider,
    Calendar,
    SelectButton,
    Skeleton,
    Checkbox,
    InputNumber,
    Button
  },
  props: {
    fault: {
      required: true
    },
    isLoading: {
      required: false
    },
    projectFileId: {
      required: true
    },
    constructionSiteId: {
      required: true
    }
  },
  setup: function (props) {
    const store = useStore()
    const toast = useToast()
    const updatedFault = ref(null)

    const sliderValue = ref(null)
    const priorityValue = ref(null)
    const realisationDate = ref(null)
    const selectedCategory = ref(null)
    const assignedUserValueCheckbox = ref({})
    const assignedUsersCheckbox = ref([])
    const subscriptionList = ref([])
    const workflows = ref([])
    const faultNameValue = ref('')
    const faultDescriptionValue = ref('')
    const newEmailValue = ref('')
    const constructionSite = computed(() => store.state.constructionSiteStore.constructionSites.filter(constuctionSite => constuctionSite.id === props.constructionSiteId))
    const loggedUser = computed(() => store.state.authStore.userDetails)

    // PAYMENT
    const isFaultPayable = ref(null)
    const isWorkerAccepted = ref(null)
    const isOwnerAccepted = ref(null)
    const priceValue = ref(null)

    // MODALS
    const faultNameModal = ref(false)
    const faultDescriptionModal = ref(false)
    const priorityOptions = [
      {
        name: 'Wysoki',
        code: 'FaultPriority.high',
        color: 'red'
      },
      {
        name: 'Średni',
        code: 'FaultPriority.medium',
        color: 'orange'
      },
      {
        name: 'Niski',
        code: 'FaultPriority.low',
        color: 'cyan'
      }
    ]
    const categoryOptions = [
      {
        name: 'Nieprzypisana',
        code: 'FaultCategory.Unassigned'
      },
      {
        name: 'Elektryczna',
        code: 'FaultCategory.Electrical'
      },
      {
        name: 'Wod.-kan. i c.o.',
        code: 'FaultCategory.PlumbingAndCentralHeating'
      },
      {
        name: 'Wykończeniowa',
        code: 'FaultCategory.Finishing'
      },
      {
        name: 'Teletechniczna',
        code: 'FaultCategory.Telecom'
      },
      {
        name: 'Went. i klima',
        code: 'FaultCategory.VentilationAndAirCon'
      },
      {
        name: 'Ogólnobudow.',
        code: 'FaultCategory.General'
      },
      {
        name: 'Inna',
        code: 'FaultCategory.Other'
      }
    ]

    const fillComponentsWithFaultOnMounted = () => {
      if (props.isLoading === false && priorityValue.value === null && selectedCategory.value === null && sliderValue.value === null) {
        updatedFault.value = JSON.parse(JSON.stringify(props.fault))
        // FILL FAULT NAME AND DESCRIPTION
        faultNameValue.value = props.fault.name
        faultDescriptionValue.value = props.fault.description

        // FILL REALISATION DATE
        realisationDate.value = props.fault.deadline ? new Date(props.fault.deadline) : null

        // FILL FAULT PAYABLE CHECKBOX
        isFaultPayable.value = props.fault.isPaid
        isWorkerAccepted.value = props.fault.workerAccepted
        isOwnerAccepted.value = props.fault.ownerAccepted
        priceValue.value = props.fault.price

        // FILL CHECKBOX WITH USERS
        props.fault.users.forEach(user => {
          assignedUsersCheckbox.value.push({
            key: user.id,
            email: user.email,
            name: user.name + ' ' + user.surname
          })
        })
        if (props.fault.UserId === undefined) {
          assignedUserValueCheckbox.value = assignedUsersCheckbox.value[0]
        } else {
          assignedUserValueCheckbox.value = assignedUsersCheckbox.value.find(user => user.key === props.fault.UserId)
        }

        // FILL SUBSCRIPTION LIST
        subscriptionList.value = JSON.parse(JSON.stringify(props.fault.emails))

        // FILL WORKFLOWS FROM FAULT
        props.fault.workflows.forEach(workflow => {
          workflows.value.push(workflow)
        })
        workflows.value.sort((a, b) => (a.date > b.date) ? 1 : -1)

        // FILL SLIDER WITH PROGRESS
        switch (props.fault.faultProgressType) {
          case 'FaultProgressType.todo':
            sliderValue.value = 0
            break
          case 'FaultProgressType.inProgress':
            sliderValue.value = 50
            break
          case 'FaultProgressType.done':
            sliderValue.value = 100
            break
        }

        // FILL PRIORITY COMPONENT
        switch (props.fault.faultPriority) {
          case 'FaultPriority.high':
            priorityValue.value = priorityOptions[0]
            break
          case 'FaultPriority.medium':
            priorityValue.value = priorityOptions[1]
            break
          case 'FaultPriority.low':
            priorityValue.value = priorityOptions[2]
            break
        }

        // FILL SELECTED FAULT CATEGORY
        switch (props.fault.faultCategory) {
          case 'FaultCategory.Unassigned':
            selectedCategory.value = categoryOptions[0]
            break
          case 'FaultCategory.Electrical':
            selectedCategory.value = categoryOptions[1]
            break
          case 'FaultCategory.PlumbingAndCentralHeating':
            selectedCategory.value = categoryOptions[2]
            break
          case 'FaultCategory.Finishing':
            selectedCategory.value = categoryOptions[3]
            break
          case 'FaultCategory.Telecom':
            selectedCategory.value = categoryOptions[4]
            break
          case 'FaultCategory.VentilationAndAirCon':
            selectedCategory.value = categoryOptions[5]
            break
          case 'FaultCategory.General':
            selectedCategory.value = categoryOptions[6]
            break
          case 'FaultCategory.Other':
            selectedCategory.value = categoryOptions[7]
            break
        }
      }
    }

    onMounted(() => fillComponentsWithFaultOnMounted())
    onBeforeUpdate(() => fillComponentsWithFaultOnMounted())

    const changeLocalization = async () => {
      await store.dispatch('projectFileStore/faultForUpdate', props.fault)
      await router.push({ path: '/construction-site/' + props.constructionSiteId + '/project-file/' + props.projectFileId, query: { change: true } })
    }

    // MODALS
    const toggleFaultNameModal = () => {
      faultNameModal.value = !faultNameModal.value
    }

    const submitFaultNameModal = () => {
      toggleFaultNameModal()
      updatedFault.value.name = faultNameValue.value
    }

    const toggleFaultDescriptionModal = () => {
      faultDescriptionModal.value = !faultDescriptionModal.value
    }

    const submitFaultDescriptionModal = () => {
      toggleFaultDescriptionModal()
      updatedFault.value.description = faultDescriptionValue.value
    }

    // ADD NEW EMAIL
    const submitAddNewEmail = () => {
      if (!subscriptionList.value.find(element => element.email === newEmailValue.value)) {
        subscriptionList.value.push({ id: createId(), email: newEmailValue.value })
      }
      newEmailValue.value = ''
    }

    // REMOVE EMAIL FROM LIST
    const removeEmailListItem = (index) => {
      subscriptionList.value.splice(index, 1)
    }

    // SAVE FAULT
    const handleSaveFault = async () => {
      const faultToUpdate = new Fault(JSON.parse(JSON.stringify(props.fault)))

      faultToUpdate.name = faultNameValue.value
      faultToUpdate.description = faultDescriptionValue.value
      faultToUpdate.price = priceValue.value
      faultToUpdate.ownerAccepted = isOwnerAccepted.value
      faultToUpdate.workerAccepted = isWorkerAccepted.value
      faultToUpdate.faultCategory = selectedCategory.value.code
      faultToUpdate.faultPriority = priorityValue.value.code
      faultToUpdate.deadline = realisationDate.value === null || realisationDate.value === '' ? null : realisationDate.value.getTime()
      faultToUpdate.emails = subscriptionList.value
      faultToUpdate.isPaid = isFaultPayable.value
      faultToUpdate.UserId = assignedUserValueCheckbox.value.key
      faultToUpdate.lastUpdate = new Date().getTime()

      switch (sliderValue.value) {
        case 0:
          faultToUpdate.faultProgressType = 'FaultProgressType.todo'
          break
        case 50:
          faultToUpdate.faultProgressType = 'FaultProgressType.inProgress'
          break
        case 100:
          faultToUpdate.faultProgressType = 'FaultProgressType.done'
          break
      }

      if (faultToUpdate.name !== props.fault.name) {
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: 'Zmieniono nazwę: ' + faultToUpdate.name,
          date: new Date().getTime()
        }))
      }
      if (faultToUpdate.description !== props.fault.description) {
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: 'Zmieniono opis: ' + faultToUpdate.description,
          date: new Date().getTime()
        }))
      }
      if (faultToUpdate.price !== props.fault.price) {
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: 'Właściciel - Zmiana kosztu usługi: ' + faultToUpdate.price + ' PLN',
          date: new Date().getTime()
        }))
      }
      if (faultToUpdate.isPaid !== props.fault.isPaid) {
        if (faultToUpdate.isPaid === false) {
          workflows.value.push(new Workflow({
            id: createId(),
            workflow: 'Właściciel - Usunięcie kosztu usługi',
            date: new Date().getTime()
          }))
        }
      }
      if (faultToUpdate.deadline !== props.fault.deadline) {
        if (faultToUpdate.deadline !== null) {
          workflows.value.push(new Workflow({
            id: createId(),
            workflow: 'Zmieniono datę realizacji: ' + new Date(faultToUpdate.deadline).toLocaleDateString(),
            date: new Date().getTime()
          }))
        }
      }
      if (JSON.stringify(faultToUpdate.emails) !== JSON.stringify(props.fault.emails)) {
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: 'Zmiana listy powiadomień email: (' + faultToUpdate.emails.map(element => element.email + ' ') + ')',
          date: new Date().getTime()
        }))
      }
      if (faultToUpdate.ownerAccepted !== props.fault.ownerAccepted) {
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: faultToUpdate.ownerAccepted ? 'Właściciel - Akceptacja kosztu usługi ' + faultToUpdate.price + ' PLN' : 'Właściciel - Odrzucenie kosztu usługi ' + faultToUpdate.price + ' PLN',
          date: new Date().getTime()
        }))
      }
      if (faultToUpdate.workerAccepted !== props.fault.workerAccepted) {
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: faultToUpdate.workerAccepted ? 'Pracownik - Akceptacja kosztu usługi ' + faultToUpdate.price + ' PLN' : 'Pracownik - Odrzucenie kosztu usługi ' + faultToUpdate.price + ' PLN',
          date: new Date().getTime()
        }))
      }
      if (faultToUpdate.faultCategory !== props.fault.faultCategory) {
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: 'Zmiana kategorii: ' + selectedCategory.value.name,
          date: new Date().getTime()
        }))
      }
      if (faultToUpdate.faultPriority !== props.fault.faultPriority) {
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: 'Zmiana priorytetu: ' + priorityValue.value.name,
          date: new Date().getTime()
        }))
      }
      if (faultToUpdate.UserId !== props.fault.UserId) {
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: 'Zmieniono przypisanie: ' + assignedUserValueCheckbox.value.name,
          date: new Date().getTime()
        }))
      }
      if (faultToUpdate.faultProgressType !== props.fault.faultProgressType) {
        let progressName = ''
        switch (faultToUpdate.faultProgressType) {
          case 'FaultProgressType.todo':
            progressName = 'Do naprawy'
            break
          case 'FaultProgressType.inProgress':
            progressName = 'W trakcie'
            break
          case 'FaultProgressType.done':
            progressName = 'Naprawiona'
            break
        }
        workflows.value.push(new Workflow({
          id: createId(),
          workflow: 'Zmiana progresu: ' + progressName,
          date: new Date().getTime()
        }))
      }
      faultToUpdate.workflows = workflows.value

      await store.dispatch('projectFileStore/updateFault', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFileId,
        fault: JSON.parse(JSON.stringify(faultToUpdate))
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Aktualizacja usterki',
            detail: 'Pomyślnie zaktualizowano daną usterkę.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Aktualizacja usterki',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    return {
      selectedCategory,
      categoryOptions,
      assignedUsersCheckbox,
      assignedUserValueCheckbox,
      sliderValue,
      priorityValue,
      priorityOptions,
      subscriptionList,
      parseDateSinceEpoch,
      realisationDate,
      isFaultPayable,
      isWorkerAccepted,
      isOwnerAccepted,
      priceValue,
      toggleFaultNameModal,
      submitFaultNameModal,
      submitFaultDescriptionModal,
      toggleFaultDescriptionModal,
      submitAddNewEmail,
      removeEmailListItem,
      faultNameValue,
      faultDescriptionValue,
      newEmailValue,
      faultNameModal,
      faultDescriptionModal,
      updatedFault,
      handleSaveFault,
      workflows,
      constructionSite,
      loggedUser,
      changeLocalization
    }
  }
}
</script>

<style lang="scss" scoped>
.faultContentWrapper {
  padding: 3% 18%;

  h3, h4 {
    font-weight: 600;
  }

  .categorySection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  .nameAndDescriptionSection {
    width: 100%;
    background-color: white;
    padding: 25px;
    margin-bottom: 25px;

    h4 {
      margin-bottom: 25px;
      cursor: pointer;
    }

    p {
      cursor: pointer;
    }
  }

  .assignedSection {
    margin-bottom: 25px;

    .skeletonBox {
      width: 65%;
      padding: 25px;
      background-color: white;
    }

    h3 {
      margin-bottom: 15px;
    }
  }

  .subscriptionList {
    .newEmailRow{
      align-items: center;
      padding: 0 0 10px 0;

      .field{
        width: 250px;

        ::v-deep(input){
          margin-top: 0;
        }
      }

      Button{
        height: 35px;
        width: 100px;
      }
    }

    h3{
      padding-bottom: 15px;
    }
    .subscriptionItem {
      padding: 15px;
      background-color: white;
      margin-bottom: 15px;

      i:hover {
        color: #5a5a5a;
        cursor: pointer;
      }
    }

    .skeletonBox {
      width: 100%;
      padding: 25px;
      background-color: white;
    }
  }

  .historySection {
    h3 {
      margin-bottom: 15px;
    }

    .box {
      width: 80%;
      background-color: white;
      padding: 25px 25px 10px 25px;

      .workflow {
        margin-bottom: 15px;

        h6 {
          font-weight: 600;
        }
      }
    }
  }

  .realisationData {
    margin-bottom: 25px;
  }

  .priorityAndStatusSection {
    width: 100%;
    background-color: white;
    padding: 25px;
    margin-bottom: 25px;

    h3 {
      margin-bottom: 15px;
    }

    .priority {
      margin-bottom: 25px;

      .priorityCard {
        margin-right: auto;
        margin-left: auto;

        .circle {
          margin-left: auto;
          margin-right: auto;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-bottom: 5px;
        }
      }
    }

    .status {

      .slider {
        padding: 0 45px
      }

      .labels {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .payableFaultSection {
    width: 100%;
    background-color: white;
    padding: 25px;
    margin-bottom: 25px;

    h3 {
      margin-bottom: 15px;
    }

    .isFaultPayable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }

    .afterIsFaultPayable {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .priceBox {
        margin: 5px;
      }
    }
  }

  .saveButton {
    float: right;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 5px;
  }

  ::v-deep(.p-buttonset .p-button) {
    width: 33.334%;
  }

  ::v-deep(.p-selectbutton .p-button.p-highlight) {
    background: #e7e7e7;
  }

  ::v-deep(.p-selectbutton .p-button) {
    background: white;
    color: black;
  }

  ::v-deep(.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover) {
    background: #e7e7e7;
    color: black;
  }

  ::v-deep(.p-selectbutton .p-button.p-highlight:hover) {
    color: black;
    background: #e7e7e7;
  }

  ::v-deep(.p-button:focus) {
    box-shadow: none;
  }

  ::v-deep(.p-inputtext) {
    width: 40%;
  }
}

</style>
