<template>
  <div class="projectFileContentDocumentsWrapper p-mx-auto p-mt-5">
    <FileUpload
      :key="fileUploadKey"
      mode="basic"
      accept=".pdf"
      chooseLabel="Dodaj nowy dokument"
      :customUpload="true"
      :auto="true"
      @uploader="uploadNewDocument"
      class='p-mx-4'
      :disabled="constructionSite[0].role === 'Worker'"
    />
    <div class="box p-shadow-1 p-mx-4">
      <div v-if="isLoading">
        <Skeleton width="70%" height="16px"/>
        <br/>
        <Skeleton width="40%" height="6px"/>
      </div>
      <div v-if="!isLoading && documents?.length > 0">
        <div v-for="(document, index) in documents" :key="document.id">
          <ProjectFileContentDocumentsCard
            :document="document"
            :constructionSiteId="constructionSiteId"
            :index="index"
          />
          <div class="divider div-transparent" v-if="documents.length !== index + 1"/>
        </div>
      </div>
      <div v-if="!isLoading && documents?.length === 0">
        Obiekt nie posiada dokumentów.
      </div>
    </div>
  </div>

</template>

<script>
import ProjectFileContentDocumentsCard from '@/components/project_file/ProjectFileContentDocumentsCard'
import Skeleton from 'primevue/skeleton'
import FileUpload from 'primevue/fileupload'
import router from '@/router'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import { clearDocumentName, createId } from '@/utils/util'
import Documents from '@/models/documents_model'

export default {
  name: 'ProjectFileContentDocuments',
  components: {
    ProjectFileContentDocumentsCard,
    Skeleton,
    FileUpload
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false
    },
    constructionSiteId: {
      type: String,
      required: true
    },
    documents: {
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const toast = useToast()
    const fileUploadKey = ref(createId())
    const constructionSite = computed(() => store.state.constructionSiteStore.constructionSites.filter(constuctionSite => constuctionSite.id === props.constructionSiteId))

    const uploadNewDocument = async (event) => {
      const file = event.files[0]
      const newDocument = new Documents({ id: createId(), name: clearDocumentName(file.name) })

      const formData = new FormData()
      formData.append('file', file)
      formData.append('id', newDocument.id)
      formData.append('fileName', newDocument.name)

      await store.dispatch('projectFileStore/uploadDocument', {
        constructionSiteId: props.constructionSiteId,
        document: newDocument,
        formData: formData
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Dodawanie dokumentu',
            detail: 'Pomyślnie dodano nowy document.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Problem z dodaniem dokumentu',
            detail: 'Sprawdź, czy dokument o tej nazwie nie został już dodany.',
            life: 3000
          })
        }
      )

      fileUploadKey.value = createId()
    }

    return { uploadNewDocument, fileUploadKey, constructionSite }
  }
}
</script>

<style lang="scss" scoped>
.projectFileContentDocumentsWrapper {
  max-width: 1450px;

  .uploadNewDocumentBox {
    display: flex;
    align-items: center;

    Button:nth-child(2){
      margin-right: 15px;
    }
  }

  .box {
    margin-top: 16px;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 3px;
  }

  .divider {
    position: relative;
    margin: 25px 0;
    height: 2px;
  }

  .div-transparent:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(200, 200, 200), transparent);
  }

  @media screen and (max-width: 767px) {
    padding: 15px 5px;

    .box {
      justify-content: center;
    }
  }
}
</style>
