import ConstructionSite from '@/models/construction_site_model'
import authHeader from '@/services/auth_header'
import authContentTypeHeader from '@/services/auth_content_type_header'
import authOctetHeader from '@/services/auth_octet_header'
import authUploadFile from '@/services/auth_upload_file'
import User from '@/models/user_model'
import instance from '@/services/api'
import { handleRequestError } from '@/utils/util'

class ConstructionSiteService {
  async fetch () {
    try {
      const response = await instance.get('/construction-sites', { headers: authHeader() })
      const constructionSiteList = []
      for (const rawConstructionSite of response.data) {
        constructionSiteList.push(new ConstructionSite(rawConstructionSite))
      }

      const thumbnails = await this.getThumbnails(constructionSiteList)
      for (let i = 0; i < thumbnails.length; i++) {
        if (thumbnails[i].status === 'fulfilled') {
          constructionSiteList[i].imageUrl = URL.createObjectURL(thumbnails[i].value.data)
        }
      }

      return Promise.resolve(constructionSiteList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchConstructionSiteUsers (id) {
    try {
      const response = await instance.get('/construction-sites/' + id + '/users', { headers: authHeader() })
      const usersList = []
      for (const assignedUser of response.data) {
        usersList.push(new User(assignedUser))
      }
      return Promise.resolve(usersList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchUserFromDb (email) {
    try {
      const response = await instance.get('/construction-sites/users?email=' + email, { headers: authHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async changeUsersRole (constructionSiteId, userId, userWithRole) {
    try {
      await instance.put('/construction-sites/' + constructionSiteId + '/users/' + userId, {
        email: userWithRole.email,
        role: userWithRole.role
      }, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async assignNewUsers (constructionSiteId, newUserList) {
    try {
      const response = await instance.post('/construction-sites/' + constructionSiteId + '/users', newUserList, { headers: authContentTypeHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // async sendInvite (newUserEmail) {
  //   try {
  //     const response = await instance.post('/invitation?email=' + newUserEmail, '', { headers: authContentTypeHeader() })
  //     return Promise.resolve(response)
  //   } catch (error) {
  //     return Promise.reject(error)
  //   }
  // }

  async sendInvite (newUserEmail) {
    const options = {
      method: 'post',
      url: '/invitation?email=' + newUserEmail,
      headers: { accept: '*/*' }
    }

    const response = await instance(options).then(async response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })
    return Promise.resolve(response)
  }

  async add (newConstructionSite) {
    try {
      await instance.post('/construction-sites', {
        id: newConstructionSite.id,
        name: newConstructionSite.name,
        lastUpdate: newConstructionSite.lastUpdate
      }, { headers: authContentTypeHeader() })
      return Promise.resolve(newConstructionSite)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async delete (id) {
    try {
      await instance.delete('/construction-sites/' + id, { headers: authHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async changeName (constructionSite) {
    try {
      await instance.put('/construction-sites', {
        id: constructionSite.id,
        name: constructionSite.name,
        lastUpdate: constructionSite.lastUpdate
      }, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async _getImage (constructionSiteId) {
    try {
      const response = await instance.get('/construction-sites/' + constructionSiteId + '/image', { headers: authOctetHeader(), responseType: 'blob' })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getThumbnails (constructionsSites) {
    const array = await Promise.allSettled(constructionsSites.map((constructionSite) => constructionSite.imageId ? this._getImage(constructionSite.id) : Promise.reject(Error('No thumbnail'))))
    return array
  }

  async uploadImage (constructionSiteId, formData) {
    try {
      const response = await instance.post('/construction-sites/' + constructionSiteId + '/image', formData, { headers: authUploadFile(), responseType: 'blob' })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async generateReport (constructionSiteId) {
    try {
      const response = await instance.post('/construction-sites/' + constructionSiteId + '/report', { locale: 'pl' }, { headers: authHeader(), responseType: 'blob' })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async generateFilterReport (constructionSiteId, formData) {
    try {
      const response = await instance.post('/construction-sites/' + constructionSiteId + '/report', formData, { headers: authContentTypeHeader(), responseType: 'blob' })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async generateSingleFaultReport (constructionSiteId, projectFileId, faultId) {
    try {
      const response = await instance.post('/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/report', { locale: 'pl' }, { headers: authContentTypeHeader(), responseType: 'blob' })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new ConstructionSiteService()
