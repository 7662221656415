import authHeader from '@/services/auth_header'
import authOctetHeader from '@/services/auth_octet_header'
import authUploadFile from '@/services/auth_upload_file'
import instance from '@/services/api'

class DocumentService {
  async delete (constructionSiteId, id) {
    try {
      await instance.delete('/construction-sites/' + constructionSiteId + '/documents/' + id, { headers: authHeader() })

      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async download (constructionSiteId, id) {
    try {
      const response = await instance.get('/construction-sites/' + constructionSiteId + '/documents/' + id, { headers: authOctetHeader(), responseType: 'blob' })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async upload (constructionSiteId, id, formData) {
    try {
      const response = await instance.post('/construction-sites/' + constructionSiteId + '/documents', formData, { headers: authUploadFile() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new DocumentService()
