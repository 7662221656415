<template>
  <Dialog header="Wybierz typ akcji" v-model:visible="modalVisible" :modal="true">
    <div class="p-d-flex p-flex-column modalContentWrapper">
      <Dropdown v-model="selectedType" :options="types" optionLabel="name"></Dropdown>
      <div class="p-d-flex p-dir-col p-mt-3 p-ml-3">
        <p class="p-mb-1">{{ fault.name }}</p>
        <p class="p-mb-1">{{ fault.description }}</p>
        <p class="p-mb-1">Przypisano do: {{ assignedUser }}</p>
      </div>
      <p class="p-mt-3"> Czy jesteś pewien? </p>
      </div>
    <div class="modalButtonSection p-d-flex p-jc-end">
      <Button @click="toggle" label="Zamknij" class="p-button-outlined p-mr-2"/>
      <Button @click="submit(selectedType.value)" label="Tak" class="p-button-raised"/>
    </div>
  </Dialog>
</template>

<script>
import { computed, ref } from 'vue'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'

export default {
  name: 'ArchiveDeleteModal',
  components: {
    Dialog,
    Dropdown,
    Button
  },
  props: {
    toggle: {
      type: Function,
      required: true
    },
    submit: {
      type: Function,
      required: true
    },
    fault: {
      type: Object,
      required: true
    },
    assignedUser: {
      type: String,
      required: false
    }
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const modalVisible = computed({
      get () {
        return props.visible
      },
      set (value) {
        emit('update:visible', value)
      }
    })
    const types = [
      { name: 'Archiwizuj', value: 'Archive' },
      { name: 'Usuń', value: 'Delete' }
    ]
    const selectedType = ref(types[0])

    return { loading, modalVisible, types, selectedType }
  }
}
</script>

<style lang="scss" scoped>
.modalContentWrapper{
  padding-bottom: 20px;
}
</style>
