<template>
  <div class="constructionSiteContentWrapper">
    <div class="p-grid">
      <div class="p-col-12 box">
        <Dropdown v-model="selectedSortType" :options="sortOptions" optionLabel="name"/>
      </div>
      <div class="p-col-12 p-md-6 p-sm-12 box">
        <Button @click="toggleAddConstructionSiteModal" label="Dodaj nowy obiekt" icon="pi pi-plus" iconPos="left"
                :disabled="isLoading"/>
      </div>
      <div class="p-col-12 p-md-6 p-sm-12 box">
        <span class="p-input-icon-left">
            <i class="pi pi-search"/>
            <InputText type="text" v-model="localSearchValue" placeholder="Wyszukaj obiekty" :disabled="isLoading"/>
        </span>
      </div>
      <div v-if="isLoading" class="p-col-12 p-lg-4 p-md-6">
        <SkeletonCard/>
      </div>
      <div v-else class="p-col-12 p-lg-4 p-md-6" v-for="constructionSite in constructionSites"
           :key="constructionSite.id">
        <ConstructionSiteCard :constructionSite="constructionSite"/>
      </div>
    </div>
  </div>

  <BasicFormModal
    v-model:modelValue="newConstructionSiteNameInputValue"
    v-model:visible="addConstructionSiteModal"
    :submit="submitAddNewConstructionSiteModal"
    :toggle="toggleAddConstructionSiteModal"
    header="Dodawanie nowego obiektu"
    placeholder="Nazwa obiektu"
  />
</template>

<script>
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import ConstructionSiteCard from '@/components/construction_site/ConstructionSiteCard'

import { ref, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import SkeletonCard from '@/components/SkeletonCard'
import ConstructionSite from '@/models/construction_site_model'
import { createId } from '@/utils/util'
import { useStore } from 'vuex'
import router from '@/router'
import BasicFormModal from '@/components/modals/BasicFormModal'
import Dropdown from 'primevue/components/dropdown/Dropdown'

export default {
  name: 'ConstructionSiteContent',
  components: {
    BasicFormModal,
    SkeletonCard,
    Button,
    InputText,
    Dropdown,
    ConstructionSiteCard
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    constructionSites: {
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const toast = useToast()

    const localSearchValue = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    const addConstructionSiteModal = ref(false)
    const newConstructionSiteNameInputValue = ref('')

    const sortOptions = [
      {
        name: 'Ostatnio otwierane',
        code: 'lastOpened'
      },
      {
        name: 'Nazwa',
        code: 'alphabetic'
      }
    ]
    const selectedSortType = computed({
      get () {
        return store.state.constructionSiteStore.sortOption
      },
      set (value) {
        return store.dispatch('constructionSiteStore/changeSortType', value)
      }
    })
    const toggleAddConstructionSiteModal = () => {
      addConstructionSiteModal.value = !addConstructionSiteModal.value
      newConstructionSiteNameInputValue.value = ''
    }

    const submitAddNewConstructionSiteModal = () => {
      const newConstructionSite = new ConstructionSite({ id: createId(), name: newConstructionSiteNameInputValue.value, role: 'Owner' })
      toggleAddConstructionSiteModal()
      store.dispatch('constructionSiteStore/add', newConstructionSite).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Dodawanie obiektu',
            detail: 'Pomyślnie dodano nowy obiekt.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Dodawanie obiektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    return {
      localSearchValue,
      newConstructionSiteNameInputValue,
      addConstructionSiteModal,
      sortOptions,
      selectedSortType,
      submitAddNewConstructionSiteModal,
      toggleAddConstructionSiteModal
    }
  }
}
</script>

<style lang="scss" scoped>

.constructionSiteContentWrapper {
  padding: 3% 18%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .box:nth-child(3) {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 5px;

    .box {
      justify-content: center;
    }
  }
}

</style>
