import { defineRule } from 'vee-validate'

defineRule('required', value => {
  if (!value || !value.length) {
    return '* Pole jest wymagane.'
  }

  return true
})

defineRule('min', (value, [min]) => {
  if (value && value.length < min) {
    return `Hasło powinno mieć przynajmniej ${min} znaków`
  }

  return true
})

defineRule('confirmed', (value, [other]) => {
  if (value !== other) {
    return 'Hasła różnią się'
  }

  return true
})

defineRule('email', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return '* Podaj prawidłowy adres e-mail.'
  }

  return true
})

defineRule('password', value => {
  const lowerCaseLetters = /[a-z]/g
  const upperCaseLetters = /[A-Z]/g
  const numbers = /[0-9]/g
  const special = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-="
  const specialChars = (value) => {
    for (let i = 0; i < special.length; i++) {
      if (value.indexOf(special[i]) > -1) {
        return true
      }
    }
  }

  if (!value || !value.length) {
    return true
  }
  if (value.length < 8) {
    return 'Za krótkie'
  } else if (!value.match(lowerCaseLetters)) {
    return 'Brak małej litery'
  } else if (!value.match(upperCaseLetters)) {
    return 'Brak wielkiej litery'
  } else if (!value.match(numbers)) {
    return 'Brak liczby'
  } else if (!specialChars(value)) {
    return 'Brak znaku specjalnego'
  } else {
    return true
  }
})

defineRule('text', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[A-Za-zśćżźłąóę]*$/.test(value)) {
    return '* Pole powinno zawierać same litery.'
  }

  return true
})

defineRule('number', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[0-9]*$/.test(value)) {
    return '* Pole powinno zawierać same cyfry.'
  }

  return true
})

defineRule('postCode', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^\d{2}-\d{3}$/.test(value)) {
    return '* Pole powinno zawierać kod pocztowy.'
  }

  return true
})

defineRule('textNumber', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[0-9A-Za-zśćżźłąóę]*$/.test(value)) {
    return '* Pole powinno zawierać cyfry lub litery.'
  }

  return true
})

defineRule('phoneNumber', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^\d{3}-\d{3}-\d{3}$/.test(value)) {
    return '* Podaj prawidłowy numer telefonu.'
  }

  return true
})

defineRule('fiscalCode', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^\d{3}-\d{3}-\d{2}-\d{2}$/.test(value)) {
    return '* Podaj prawidłowy NIP.'
  }

  return true
})
