import axios from 'axios'
import registerUserContentTypeHeader from '@/services/register_user_content_type_header'
import instance from '@/services/api'
import setupInterceptor from '@/services/setupInterceptor'
import SecureStorage from '@/services/SecureStorage'
import textPlainHeader from '@/services/text_plain_header'

const ls = SecureStorage.get()

class AuthService {
  async login (user) {
    const params = new URLSearchParams()
    params.append('email', user.email.trim())
    params.append('password', user.password.trim())

    return axios.post(process.env.VUE_APP_BASE_BACKEND_URL + '/login', params)
      .then(this.handleResponse)
      .then(response => {
        if (response.data) {
          ls.set('user', response.data)
          setupInterceptor(response.data)
        }

        return response.data
      })
  }

  async logout () {
    await ls.clear()
  }

  async register (user) {
    const options = {
      method: 'post',
      url: process.env.VUE_APP_BASE_BACKEND_URL + '/registration',
      data: {
        id: user.id,
        name: user.name,
        surname: user.surname,
        email: user.email,
        password: user.password,
        company: user.company,
        department: user.department,
        position: user.position,
        city: user.city,
        street: user.street,
        addressNumber: user.addressNumber,
        zipCode: user.zipCode
      },
      headers: registerUserContentTypeHeader()
    }

    return axios(options).then(this.handleResponse).then(response => {
      return response.data
    })
  }

  async fetchDetails () {
    try {
      const response = await instance.get('/construction-sites/users/details', { headers: { accept: 'application/json', 'Content-Type': 'application/json' } })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async handleResponse (response) {
    if (response.status === 401) {
      this.logout()
      location.reload(true)

      const error = response.data && response.data.message
      return Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  async sendEmailForPasswordReminder (email) {
    try {
      await axios.put(process.env.VUE_APP_BASE_BACKEND_URL + '/construction-sites/users/resetPassword/', email, { headers: textPlainHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async resetPassword (payload) {
    try {
      await axios.put(process.env.VUE_APP_BASE_BACKEND_URL + '/construction-sites/users/changePassword/' + payload.codeValue, payload.passwordValue, { headers: textPlainHeader() })
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new AuthService()
