<template>
  <div class="faultMessagesContentWrapper">
    <div v-if="isLoading || loadingChat">
      <div class="chatMessages">
        <div class="messageWrapper">
          <div class="messageText">
            <Skeleton width="100%" height="20px"/>
          </div>
        </div>
      </div>
      <div class="chatFooter p-shadow-1">
        <Skeleton width="100%" height="20px"/>
      </div>
      </div>
    <div v-if="!isLoading && !loadingChat">
      <div class="chatMessages">
        <div v-if="messages.length <= 0">
          <div class="p-d-flex p-dir-col p-ai-center p-jc-center">
            <span> Brak wiadomości w usterce.</span>
          </div>
        </div>
        <div v-else>
          <div v-for="(faultMessage, index) in messages" :key="index">
            <div class="p-d-flex" :style="loggedUser.id === faultMessage.UserId ? { 'justify-content': `flex-end`} : { 'justify-content': `flex-start`}">
              <div class="p-flex-column">
                <div class="messageInfo" :style="loggedUser.id === faultMessage.UserId ? { 'text-align': `end`} : { 'text-align': `start`}">
                  <span>{{  faultMessage.userName }}, {{ faultMessage.dateTime }}</span>
                </div>
                <div :class="loggedUser.id === faultMessage.UserId ? 'messageWrapperRight p-shadow-1' : 'messageWrapperLeft p-shadow-1'">
                  <div class="messageText" :style="loggedUser.id === faultMessage.UserId ? { 'text-align': `end`} : { 'text-align': `start`}">
                    <span> {{ faultMessage.message }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chatFooter p-shadow-1">
        <input @keyup.enter="sendMessage" v-model="inputValue" type="text" placeholder="Wpisz treść wiadomości">
        <div class=""></div>
        <i @click="sendMessage"  class="pi pi-chevron-right"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import Skeleton from 'primevue/skeleton'
import Message from '@/models/message_model'
import { getCurrentTime, parseDateSinceEpoch } from '@/utils/util'
import { useToast } from 'primevue/usetoast'
import SecureStorage from '@/services/SecureStorage'

export default {
  name: 'FaultContentMessages',
  props: {
    fault: {
      required: true
    },
    isLoading: {
      required: false
    }
  },
  components: {
    Skeleton
  },
  setup (props) {
    const ls = SecureStorage.get()
    const user = ls.get('user')

    const store = useStore()
    const toast = useToast()

    const users = computed(() => store.state.projectFileStore.users)
    const inputValue = ref()
    const messages = ref([])
    const loadingChat = ref(true)
    const webSocketUrl = process.env.VUE_APP_BASE_WEBSOCKET_URL + '/chat/faults/' + props.fault.id
    const loggedUser = computed(() => store.state.authStore.userDetails)
    let client = null
    const connectToWebsocket = () => {
      client = new WebSocket(webSocketUrl, [user, 'chat'])

      client.onopen = function (event) {
        toast.add({
          severity: 'success',
          summary: 'Czat',
          detail: 'Połączono z czatem usterki.',
          life: 3000
        })
        loadingChat.value = false
      }

      client.onmessage = function (event) {
        const eventData = JSON.parse(event.data)
        const newMessage = new Message({
          faultId: props.fault.id,
          UserId: eventData.UserId,
          message: eventData.message,
          dateTime: parseDateSinceEpoch(eventData.dateTime),
          isOwner: props.fault.UserId === eventData.UserId,
          userName: computed(() => {
            for (let i = 0; i < users.value.length; i++) {
              if (users.value[i].id === eventData.UserId) {
                return users.value[i].name + ' ' + users.value[i].surname
              }
            }
            return 'Brak danych'
          })
        })

        messages.value.push(newMessage)
      }

      client.onerror = function (event) {
        toast.add({
          severity: 'warn',
          summary: 'Czat',
          detail: 'Wystąpił błąd, nastąpi nawiązanie ponownego połączenia z czatem.',
          life: 3000
        })
        client.close()
        setTimeout(function () {
          connectToWebsocket()
        }, 1000)
      }

      client.onclose = function (event) {
        toast.add({
          severity: 'warn',
          summary: 'Czat',
          detail: 'Zamknięto czat usterki.',
          life: 3000
        })
      }
    }

    const sendMessage = () => {
      const newMessage = {
        message: inputValue.value,
        dateTime: getCurrentTime()
      }

      client.send(JSON.stringify(newMessage))
      inputValue.value = ''
    }

    onMounted(() => connectToWebsocket())
    onBeforeUnmount(() => {
      client.close()
    })

    return { messages, loadingChat, sendMessage, inputValue, loggedUser }
  }
}
</script>

<style lang="scss" scoped>
.faultMessagesContentWrapper{
  padding: 3% 7%;

  .messageInfo{
    margin: 0 0 10px 0;
    font-size: 0.9rem;
  }

  .messageWrapperLeft{
    display: inline-block;
    max-width: 600px;
    padding: 10px 25px;
    -webkit-border-bottom-left-radius: 25px;
    -webkit-border-top-right-radius: 25px;
    -webkit-border-bottom-right-radius: 25px;
    -moz-border-radius-bottomleft: 25px;
    -moz-border-radius-topright: 25px;
    -moz-border-radius-bottomright: 25px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: #ffffff;
  }

  .messageWrapperRight{
    display: inline-block;
    max-width: 600px;
    padding: 10px 25px;
    -webkit-border-bottom-left-radius: 25px;
    -webkit-border-top-left-radius: 25px;
    -webkit-border-bottom-right-radius: 25px;
    -moz-border-radius-bottomleft: 25px;
    -moz-border-radius-topright: 25px;
    -moz-border-radius-bottomright: 25px;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: #ffffff;
  }

  .chatMessages {
    height: 70vh;
    padding: 15px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .chatFooter {
    position: absolute;
    bottom: 30px;
    width: 85%;
    margin-top: 10px;
    padding: 15px 20px;
    font-weight: 400;
    background-color: white;
    display: flex;
    justify-self: auto;
    justify-content: space-between;
    align-items: center;

    input {
      padding: 5px;
      width: 100%;
      color: black;
      outline: 0;
      border-width: 0 0 1px;
      border-color: white;
      margin-right: 5px;

      &::placeholder {
        color: gray;
      }

      &:focus {
        border-width: 0 0 1px;
        border-color: #007bff;
      }
    }

    i {
      color: black;
      font-size: 18px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 15px 5px;
    .chatFooter {
      width: 100%;
    }
  }
}
</style>
