<template>
  <Navbar :items="navbarItems" screenName="constructionSite"/>

  <ConstructionSiteContent
    :constructionSites="constructionSites"
    :isLoading="isLoading"
    v-model:modelValue="searchValue"
  />
</template>
<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import Navbar from '@/components/Navbar'
import ConstructionSiteContent from '@/components/construction_site/ConstructionSiteContent'

export default {
  name: 'ConstructionSite',
  components: { ConstructionSiteContent, Navbar },
  setup () {
    const store = useStore()
    const isLoading = ref(false)
    const searchValue = ref('')
    const selectedSortType = computed(() => JSON.parse(JSON.stringify(store.state.constructionSiteStore.sortOption)))
    const navbarItems = [
      {
        id: 1,
        label: 'Obiekty',
        to: '/'
      }
    ]

    const constructionSites = computed(() => store.getters['constructionSiteStore/getConstructionSites'](searchValue.value))

    onMounted(async () => {
      isLoading.value = true
      await store.dispatch('constructionSiteStore/fetch').then(
        () => {
        },
        async () => {
          await store.dispatch('authStore/logout')
        }
      )
      await store.dispatch('constructionSiteStore/changeSortType', selectedSortType.value)
      isLoading.value = false
    })

    return { selectedSortType, constructionSites, isLoading, searchValue, navbarItems }
  }
}
</script>
