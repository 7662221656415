<template>
  <div class="projectCanvasContentWrapper" @mouseup="handleMouseUp" @mousemove.prevent="handleMouseMove">
    <div class="topLabel p-shadow-1" v-if='!isChangeLocalization'>
      <i class="pi pi-angle-left" @click="changeProjectToLeft()" v-if="!hideLeftButton" />
      <i class="pi pi-replay" @click="rotateImage(true)"/>
      <i class="pi pi-refresh" @click="rotateImage(false)"/>
      <i class="pi pi-angle-right" @click="changeProjectToRight()" v-if="!hideRightButton" />
    </div>

    <div class="box">
      <div
        v-if="imageData.size"
        class="projectImage"
        ref="draggableContainer"
        @mousedown="handleMouseDown"
        @dblclick="handleDoubleClick"
        :style="{
          width: imageData.size.width,
          height: imageData.size.height,
          top: imageData.top,
          left: imageData.left,
          transform: `rotate(${imageData.rotation}deg)`,
          backgroundImage: `url('${imageData.src}')`
        }">
        <div
          v-for="fault in projectFile.faults"
          :key="fault.id"
          class="fault"
          @click="handleNavigationToFault(fault.id, constructionSiteId, projectFile.id)"
          :style="{
            backgroundImage: 'url(' + require('@/assets/markers/' + getRightMarkerByFaultCategory(fault.faultCategory)) + ')',
            left: (fault.markerPositionDx - 32) + 'px',
            top: (fault.markerPositionDy - 64) + 'px',
            transform: `rotate(${-imageData.rotation}deg)`
          }">
          <div class="faultLabel p-shadow-1"> {{ fault.name }}</div>
        </div>
      </div>
    </div>
  </div>

  <ConfirmationModal
    v-if='!faultForUpdate'
    text="Czy chciałbyś postawić postawić usterkę w tym miejscu ?"
    v-model:visible="placeMarkerModal"
    :noPressed="togglePlaceMarkerModal"
    :yesPressed="handleModalYesPressed"
  />
  <ConfirmationModal
    v-else
    text="Czy chciałbyś zaktualizować lokalizację usterki w tym miejscu ?"
    v-model:visible="placeMarkerModal"
    :noPressed="togglePlaceMarkerModal"
    :yesPressed="handleChangeLocalization"
  />

</template>

<script>
import { onMounted, ref, reactive, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import { createId, getRightMarkerByFaultCategory } from '@/utils/util'
import Fault from '@/models/fault_model'
import router from '@/router'
import { useRoute } from 'vue-router'

export default {
  name: 'ProjectCanvasContent',
  components: {
    ConfirmationModal
  },
  props: {
    constructionSiteId: {
      required: true
    },
    projectFile: {
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const toast = useToast()
    const placeMarkerModal = ref(false)
    const draggableContainer = ref(null)
    const constructionSite = computed(() => store.state.constructionSiteStore.constructionSites.filter(constuctionSite => constuctionSite.id === props.constructionSiteId))
    const faultForUpdate = computed(() => JSON.parse(JSON.stringify(store.state.projectFileStore.faultForUpdate)))
    const projectFiles = computed(() => store.state.projectFileStore.projectFiles)
    const isChangeLocalization = ref(route.query.change)
    const imageData = reactive({
      size: null,
      src: null,
      mousePosition: null,
      offset: [0, 0],
      isDown: false,
      left: 0,
      top: 0,
      rotation: 0,
      markerX: 0,
      markerY: 0
    })
    const hideLeftButton = ref(false)
    const hideRightButton = ref(false)
    const loadPhoto = (url) => {
      const img = new Image()

      img.onload = function () {
        imageData.size = {
          width: this.width + 'px',
          height: this.height + 'px'
        }
        if (url) {
          imageData.src = url
        } else {
          imageData.src = this.src
        }
      }
      if (url) {
        img.src = url
      } else {
        img.src = props.projectFile.imageUrl
      }
    }
    const checkPosition = () => {
      if (projectFiles.value.length === 1) {
        hideLeftButton.value = true
        hideRightButton.value = true
      } else if (projectFiles.value.length === 2) {
        for (let i = 0; i < projectFiles.value.length; i++) {
          if (projectFiles.value[i].id === props.projectFile.id) {
            if (i === 0) {
              hideLeftButton.value = true
              hideRightButton.value = false
            } else {
              hideRightButton.value = true
              hideLeftButton.value = false
            }
          }
        }
      } else {
        for (let i = projectFiles.value.length - 1; i >= 0; i--) {
          if (projectFiles.value[i].id === props.projectFile.id) {
            if (i === 0) {
              hideLeftButton.value = true
            } else if (i + 1 === projectFiles.value.length) {
              hideRightButton.value = true
            } else {
              hideLeftButton.value = false
              hideRightButton.value = false
            }
          }
        }
      }
    }
    onMounted(async () => {
      loadPhoto()
      checkPosition()
    })

    watchEffect(async () => {
      loadPhoto()
      checkPosition()
    })

    const handleMouseDown = (event) => {
      imageData.isDown = true
      imageData.offset = [
        draggableContainer.value.offsetLeft - event.clientX,
        draggableContainer.value.offsetTop - event.clientY
      ]
    }

    const handleMouseUp = () => {
      imageData.isDown = false
    }

    const handleMouseMove = (event) => {
      if (imageData.isDown) {
        imageData.mousePosition = {
          x: event.clientX,
          y: event.clientY
        }
        imageData.left = (imageData.mousePosition.x + imageData.offset[0]) + 'px'
        imageData.top = (imageData.mousePosition.y + imageData.offset[1]) + 'px'
      }
    }

    const rotateImage = (isLeftRotation) => {
      if (isLeftRotation) {
        imageData.rotation = imageData.rotation - 90
        if (imageData.rotation === -360) {
          imageData.rotation = 0
        }
      } else {
        imageData.rotation = imageData.rotation + 90
        if (imageData.rotation === 360) {
          imageData.rotation = 0
        }
      }
    }

    const handleDoubleClick = async (event) => {
      if (constructionSite.value[0].role === 'Worker') {
        toast.add({
          severity: 'warn',
          summary: 'Tylko właściciel obiektu może dodać usterkę',
          life: 3000
        })
      } else {
        togglePlaceMarkerModal()
        // const clientRect = draggableContainer.value.getBoundingClientRect()
        imageData.markerX = parseFloat(Math.abs(draggableContainer.value.offsetLeft - event.clientX) + '.00001')
        imageData.markerY = parseFloat(Math.abs(draggableContainer.value.offsetTop - event.clientY) + '.00001')
        // console.log(clientRect)
        // const theta = Math.atan2(left, top) * 180 / Math.PI
        //
        // const x = left * Math.cos(theta) - top * Math.sin(theta)
        // const y = left * Math.sin(theta) + top * Math.cos(theta)

        //          |  cos(theta)   -sin(theta) |
        //          |  sin(theta)    cos(theta) |

        if (imageData.rotation === 90 || imageData.rotation === -90) {
          console.log('90 stopni')
        } else if (imageData.rotation === 180 || imageData.rotation === -180) {
          console.log('180 stopni')
        } else if (imageData.rotation === 270 || imageData.rotation === -270) {
          console.log('270 stopni')
        } else if (imageData.rotation === 360 || imageData.rotation === 0 || imageData.rotation === -360) {
          console.log('0 stopni')
        }
      }
    }

    const togglePlaceMarkerModal = () => {
      placeMarkerModal.value = !placeMarkerModal.value
      imageData.markerX = 0
      imageData.markery = 0
    }

    const handleModalYesPressed = async () => {
      const newFault = new Fault({
        id: createId(),
        name: '* Nowa usterka *',
        description: '* Opis usterki *',
        faultCategory: 'FaultCategory.Unassigned',
        faultProgressType: 'FaultProgressType.todo',
        faultPriority: 'FaultPriority.high',
        markerPositionDx: imageData.markerX,
        markerPositionDy: imageData.markerY,
        price: 0,
        isPaid: false,
        ownerAccepted: false,
        workerAccepted: false,
        isArchived: false,
        ProjectFilesId: props.projectFile.id,
        workflows: [
          {
            id: createId(),
            workflow: 'Utworzono nową usterkę.' +
            '\n Nazwa * Nowa usterka *' +
            '\n Opis  * Opis usterki *' +
            '\n Data Brak' +
            '\n Kategoria Nieprzypisana' +
            '\n Priorytet Wysoki' +
            '\n Status Do naprawy' +
            '\n Przypisany użytkownik Brak' +
      '\n Cena 0.0 zł' +
      '\n Brak akceptacji właściciela' +
      '\n Brak akceptacji pracownika' +
      '\n Lista powiadomień Brak',
            date: new Date().getTime()
          }
        ]
      })

      await store.dispatch('projectFileStore/addFault', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFile.id,
        fault: newFault
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Dodawanie usterki',
            detail: 'Pomyślnie dodano usterkę.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Dodawanie usterki',
            detail: error.message,
            life: 3000
          })
        }
      )

      togglePlaceMarkerModal()
    }

    const handleChangeLocalization = async () => {
      faultForUpdate.value.markerPositionDx = imageData.markerX
      faultForUpdate.value.markerPositionDy = imageData.markerY
      await store.dispatch('projectFileStore/updateFault', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFile.id,
        fault: faultForUpdate.value
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Aktualizacja usterki',
            detail: 'Pomyślnie zaktualizowano lokalizację usterki.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Błąd przy aktualizacji usterki',
            detail: error.message,
            life: 3000
          })
        }
      )
      togglePlaceMarkerModal()
    }

    const resetPosition = () => {
      imageData.mousePosition = null
      imageData.offset = [0, 0]
      imageData.isDown = false
      imageData.left = 0
      imageData.top = 0
      imageData.rotation = 0
      imageData.markerX = 0
      imageData.markerY = 0
    }

    const handleNavigationToFault = (faultId, constructionSiteId, projectFileId) => {
      router.push({
        path: '/fault/' + faultId,
        query: { faultId: faultId, constructionSiteId: constructionSiteId, projectFileId: projectFileId }
      })
    }
    const changeProjectToRight = () => {
      for (let i = 0; i < projectFiles.value.length; i++) {
        if (projectFiles.value[i].id === props.projectFile.id) {
          resetPosition()
          if (projectFiles.value.length === 2) {
            for (let i = 0; i < projectFiles.value.length; i++) {
              if (projectFiles.value[i].id === props.projectFile.id) {
                console.log(i)
                if (i === 0) {
                  hideLeftButton.value = false
                  hideRightButton.value = true
                  router.push('/construction-site/' + props.constructionSiteId + '/project-file/' + projectFiles.value[1].id)
                }
              }
            }
          } else {
            if (i + 2 === projectFiles.value.length) {
              router.push('/construction-site/' + props.constructionSiteId + '/project-file/' + projectFiles.value[i + 1].id)
              loadPhoto(projectFiles.value[i + 1].imageUrl)
              hideRightButton.value = true
            } else {
              router.push('/construction-site/' + props.constructionSiteId + '/project-file/' + projectFiles.value[i + 1].id)
              loadPhoto(projectFiles.value[i + 1].imageUrl)
              hideRightButton.value = false
              hideLeftButton.value = false
            }
          }
        }
      }
    }

    const changeProjectToLeft = () => {
      for (let i = projectFiles.value.length - 1; i >= 0; i--) {
        if (projectFiles.value[i].id === props.projectFile.id) {
          resetPosition()
          if (projectFiles.value.length === 2) {
            for (let i = projectFiles.value.length - 1; i >= 0; i--) {
              if (projectFiles.value[i].id === props.projectFile.id) {
                console.log(i)
                if (i === 1) {
                  hideLeftButton.value = true
                  hideRightButton.value = false
                  router.push('/construction-site/' + props.constructionSiteId + '/project-file/' + projectFiles.value[0].id)
                }
              }
            }
          } else {
            if (i === 1) {
              router.push('/construction-site/' + props.constructionSiteId + '/project-file/' + projectFiles.value[0].id)
              loadPhoto(projectFiles.value[0].imageUrl)
              hideLeftButton.value = true
            } else {
              router.push('/construction-site/' + props.constructionSiteId + '/project-file/' + projectFiles.value[i - 1].id)
              loadPhoto(projectFiles.value[i - 1].imageUrl)
              hideLeftButton.value = false
              hideRightButton.value = false
            }
          }
        }
      }
    }

    return {
      handleMouseDown,
      handleMouseUp,
      handleMouseMove,
      handleDoubleClick,
      imageData,
      draggableContainer,
      rotateImage,
      placeMarkerModal,
      togglePlaceMarkerModal,
      getRightMarkerByFaultCategory,
      handleModalYesPressed,
      handleNavigationToFault,
      faultForUpdate,
      handleChangeLocalization,
      changeProjectToRight,
      changeProjectToLeft,
      hideLeftButton,
      hideRightButton,
      isChangeLocalization
    }
  }
}
</script>

<style lang="scss" scoped>

.projectCanvasContentWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #b7b7b7;
  position: relative;

  .topLabel {
    width: 300px;
    height: 50px;
    background-color: #007bff;
    border-radius: 0 0 15px 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    left: 50%;
    margin-left: -150px;
    z-index: 1;

    i {
      font-size: 25px;
      color: white;
    }

    i:hover {
      color: #d4d4d4;
      cursor: pointer;
    }
  }

  .box {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .projectImage {
      position: absolute;
      background-color: white;
      transition: transform 1s;

      .fault {
        width: 64px;
        height: 64px;
        position: absolute;
        text-align: center;
        cursor: pointer;
        transition: transform 1s;

        .faultLabel {
          position: absolute;
          top: 70px;
          padding: 1px;
          margin-left: -18px;
          width: 100px;
          background-color: white;
          border-radius: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

</style>
