import authHeader from '@/services/auth_header'
import authContentTypeHeader from '@/services/auth_content_type_header'
import authUploadFile from '@/services/auth_upload_file'
import instance from '@/services/api'

class ProjectFileService {
  async add (constructionSiteId, projectFile, formData) {
    try {
      await instance.post('/construction-sites/' + constructionSiteId + '/projects', {
        id: projectFile.id,
        name: projectFile.name,
        type: projectFile.type,
        extension: projectFile.extension,
        lastUpdate: projectFile.lastUpdate
      }, { headers: authContentTypeHeader() })
      await this._uploadImage(constructionSiteId, projectFile.id, formData)
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async delete (constructionSiteId, id) {
    try {
      await instance.delete('/construction-sites/' + constructionSiteId + '/projects/' + id, { headers: authHeader() })

      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async update (constructionSiteId, projectFile) {
    try {
      await instance.put('/construction-sites/' + constructionSiteId + '/projects', {
        id: projectFile.id,
        name: projectFile.name,
        type: projectFile.type,
        extension: projectFile.extension,
        lastUpdate: projectFile.lastUpdate
      }, { headers: authContentTypeHeader() })

      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async _uploadImage (constructionSiteId, projectFileId, formData) {
    try {
      const response = await instance.post('/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/image',
        formData, { headers: authUploadFile() })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new ProjectFileService()
