<template>
  <Dialog :header="header" v-model:visible="modalVisible" :modal="true">
    <Form @submit="submit">
      <LabeledInput
        name="new_con_site_name_input"
        rules="required"
        type="text"
        v-model:modelValue="formValue"
        :placeholder="placeholder"
      />
      <div class="modalButtonSection">
        <Button @click="toggle" label="Zamknij" class="p-button-outlined"/>
        <Button label="Zatwierdź" type="submit"/>
      </div>
    </Form>
  </Dialog>
</template>

<script>
import { computed } from 'vue'
import Dialog from 'primevue/dialog'
import { Form } from 'vee-validate'
import LabeledInput from '@/components/LabeledInput'
import Button from 'primevue/button'

export default {
  inheritAttrs: false,
  name: 'BasicFormModal',
  components: {
    Dialog,
    Form,
    LabeledInput,
    Button
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    submit: {
      type: Function,
      required: true
    },
    toggle: {
      type: Function,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const modalVisible = computed({
      get () {
        return props.visible
      },
      set (value) {
        emit('update:visible', value)
      }
    })

    const formValue = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    return { modalVisible, formValue }
  }
}
</script>

<style lang="scss" scoped>
.modalButtonSection {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  Button:nth-child(2){
    margin-left: 10px;
  }
}
</style>
