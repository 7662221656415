<template>
  <div class="recordingTile p-shadow-1">
    <div class="p-mr-4">
      <h4>Nagranie {{ index + 1 }}</h4>
      <p>{{ recordingUser.name }} {{ recordingUser.surname }}, {{ parseDateSinceEpoch(recording.date) }}</p>
    </div>
    <audio class="p-d-inline-flex" controls>
      <source :src="recording.recordingUrl" type="audio/mp3">
    </audio>
  </div>
</template>

<script>
import { computed } from 'vue'
import { parseDateSinceEpoch } from '@/utils/util'

export default {
  name: 'FaultContentRecordingTile',
  props: {
    fault: {
      required: true
    },
    recording: {
      required: true
    },
    index: {
      required: true
    }
  },
  setup (props) {
    const recordingUser = computed(() => props.fault.users.find(user => props.recording.UserId === user.id))

    return { recordingUser, parseDateSinceEpoch }
  }
}
</script>

<style scoped lang="scss">
  .recordingTile{
    display: flex;
    padding: 15px 15px;
    margin: 10px;
    background-color: #ffffff;
  }
</style>
