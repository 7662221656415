<template>
  <Dialog header="Generowanie raportu" v-model:visible="modalVisible" :modal="true">
    <TabView v-model:activeIndex="active">
      <TabPanel header="Ogólny">
        <div class="generalWrapper">
          <h2> Raport Obiektu </h2>
          <div class="constructionSiteCardWrapper p-shadow-1">
            <div v-if="constructionSite.imageUrl !== undefined"
                 class="image"
                 :style="{ backgroundImage: `url('${constructionSite.imageUrl}')`}">
            </div>
            <div v-else class="no-image">
              <div class="p-d-flex p-dir-col p-jc-center p-ai-center no-image-text">
                <h3>Brak zdjęcia.</h3>
              </div>
            </div>
            <div class="details">
              <h3> {{ constructionSite.name }} </h3>
              <i class="pi pi-book"> Podsumowanie </i>
              <i class="pi pi-map"> Projekty </i>
              <i class="pi pi-map-marker"> Usterka </i>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel header="Z filtrami">
          <div v-if="isLoadingProjects">
            <div class="filteredWrapper">
              <div class="p-field p-col-12 p-md-4">
                <Skeleton width="100px" height="16px"/>
                <br/>
                <Skeleton width="150px" height="30px"/>
                <br/>
                <br/>
                <Skeleton width="100px" height="16px"/>
                <br>
                <Skeleton width="30vw" height="30px"/>
                <br/>
                <Skeleton width="100px" height="16px"/>
                <br>
                <Skeleton width="30vw" height="30px"/>
                <br/>
                <Skeleton width="100px" height="16px"/>
                <br>
                <Skeleton width="30vw" height="30px"/>
                <br/>
                <Skeleton width="100px" height="16px"/>
                <br>
                <Skeleton width="30vw" height="30px"/>
              </div>
            </div>
          </div>
          <div v-if="!isLoadingProjects && projectFilesList.length > 0">
            <div class="filteredWrapper">
              <div class="p-field p-col-12 p-md-4">
                <label for="startDate"> Filtrowanie od daty </label>
                <Calendar id="startDate" v-model="selectedDate" :manualInput="true" />
              </div>
              <h5>Projekty</h5>
              <MultiSelect v-model="selectedProjects" :options="projectFilesList" optionLabel="name" emptyFilterMessage="Nie znaleziono wyników." placeholder="Wybierz projekty" :filter="true" display="chip"/>
              <h5>Kategoria</h5>
              <MultiSelect v-model="selectedCategories" :options="faultCategories" optionLabel="name" emptyFilterMessage="Nie znaleziono wyników." placeholder="Wybierz kategorie usterek" :filter="true" display="chip"/>
              <h5>Status</h5>
              <MultiSelect v-model="selectedStatuses" :options="faultStatuses" optionLabel="name" emptyFilterMessage="Nie znaleziono wyników." placeholder="Wybierz statusy usterek" :filter="true" display="chip"/>
              <h5>Priorytet</h5>
              <MultiSelect v-model="selectedPriorities" :options="faultPriorities" optionLabel="name" emptyFilterMessage="Nie znaleziono wyników." placeholder="Wybierz priorytety usterek" :filter="true" display="chip"/>
            </div>
          </div>
          <div v-else-if="!isLoadingProjects && projectFilesList.length === 0">
            <div class="filteredWrapper">
              <p> Brak projektów. </p>
            </div>
          </div>
      </TabPanel>
      <TabPanel header="Szczegółowy">
        <div class="faultWrapper">
          <div v-if="isLoadingProjects">
            <Skeleton width="70px" height="16px"/>
            <br/>
            <Skeleton width="80px" height="12px"/>
            <br/>
            <Skeleton width="80px" height="12px"/>
          </div>
          <div v-if="!isLoadingProjects && projectFilesWithFaultsList.length > 0">
            <div v-for="(projectFile, index) in projectFilesWithFaultsList" :key="index">
              <h4>{{ projectFile.projectFileName }}</h4>
              <div v-for="fault in projectFile.faults" :key="fault.id" class="p-field-radiobutton">
                <RadioButton :id="fault.id" name="fault" :value="fault" v-model="selectedFault" />
                <label :for="fault.key">{{ fault.name }}</label>
              </div>
            </div>
          </div>
          <div v-else-if="!isLoadingProjects && projectFilesWithFaultsList.length === 0">
            <p> Brak usterek. </p>
          </div>
        </div>
      </TabPanel>
    </TabView>
    <div class="modalButtonSection">
      <Button @click="toggle" label="Zamknij" class="p-button-outlined"/>
      <Button v-if="active === 0" @click="sendGeneralReport" label="Wyślij" icon="pi pi-check"  autofocus />
      <Button v-if="active === 1 && !isLoadingProjects && projectFilesList.length > 0" @click="sendFilteredReport(selectedProjects, selectedCategories, selectedStatuses, selectedPriorities, selectedDate)" label="Wyślij" icon="pi pi-check"  autofocus />
      <Button v-if="active === 2 && selectedFault != null" @click="sendFaultReport(selectedFault)" label="Wyślij" icon="pi pi-check"  autofocus />
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Calendar from 'primevue/calendar'
import MultiSelect from 'primevue/multiselect'
import RadioButton from 'primevue/radiobutton'
import Skeleton from 'primevue/skeleton'
import { computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'

export default {
  name: 'GenerateReportModal',
  components: {
    Dialog,
    Button,
    TabView,
    TabPanel,
    Calendar,
    MultiSelect,
    RadioButton,
    Skeleton
  },
  props: {
    toggle: {
      type: Function,
      required: true
    },
    constructionSite: {
      type: Object,
      required: true
    },
    sendGeneralReport: {
      type: Function,
      required: true
    },
    sendFilteredReport: {
      type: Function,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    sendFaultReport: {
      type: Function,
      required: true
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const active = ref(0)
    const defaultDate = new Date()
    defaultDate.setDate(1)
    defaultDate.setMonth(defaultDate.getMonth() - 1)
    const selectedDate = ref(defaultDate)
    const isLoadingProjects = ref(false)
    const selectedProjects = ref()
    const selectedCategories = ref()
    const selectedStatuses = ref()
    const selectedPriorities = ref()
    const selectedFault = ref()
    const faultCategories = [
      { name: 'Nieprzypisane', code: 'FaultCategory.Unassigned' },
      { name: 'Ogólnobudowlane', code: 'FaultCategory.General' },
      { name: 'Wodno-kanalizacyjne i centralne ogrzewanie', code: 'FaultCategory.PlumbingAndCentralHeating' },
      { name: 'Elektryczne', code: 'FaultCategory.Electrical' },
      { name: 'Wykończeniowe', code: 'FaultCategory.Finishing' },
      { name: 'Teletechniczne', code: 'FaultCategory.Telecom' },
      { name: 'Wentylacyjne i klimatyzacji', code: 'FaultCategory.VentilationAndAirCon' },
      { name: 'Inne', code: 'FaultCategory.Other' },
      { name: 'Zarchiwizowane', code: 'FaultCategory.Archived' }
    ]
    const faultStatuses = [
      { name: 'Do naprawy', code: 'FaultProgressType.todo' },
      { name: 'W trakcie', code: 'FaultProgressType.inProgress' },
      { name: 'Naprawione', code: 'FaultProgressType.done' }
    ]
    const faultPriorities = [
      { name: 'Wysoki', code: 'FaultPriority.high' },
      { name: 'Średni', code: 'FaultPriority.medium' },
      { name: 'Niski', code: 'FaultPriority.low' }
    ]
    const projectFilesList = computed(() => {
      if (!isLoadingProjects.value) {
        const projectsFiles = []
        for (let i = 0; i < props.constructionSite.projectFiles.length; i++) {
          projectsFiles.push({ name: props.constructionSite.projectFiles[i].name, code: props.constructionSite.projectFiles[i].id })
        }

        return projectsFiles
      }
      return []
    })
    const projectFilesWithFaultsList = computed(() => {
      if (!isLoadingProjects.value) {
        const projectFilesWithFaults = []
        for (let i = 0; i < props.constructionSite.projectFiles.length; i++) {
          projectFilesWithFaults.push({
            projectFileName: props.constructionSite.projectFiles[i].name,
            faults: props.constructionSite.projectFiles[i].faults
          })
        }
        return projectFilesWithFaults
      }
      return []
    })

    const modalVisible = computed({
      get () {
        return props.visible
      },
      set (value) {
        emit('update:visible', value)
      }
    })

    watchEffect(async () => {
      if (props.isVisible) {
        isLoadingProjects.value = true
        await store.dispatch('constructionSiteStore/fetchProjects', props.constructionSite.id).then(
          () => {
            isLoadingProjects.value = false
          },
          async (error) => {
            if (error.status === 401) {
              await router.push('/')
            }
          })
        selectedFault.value = projectFilesWithFaultsList.value[0]?.faults[0]
      }
    })

    return {
      active,
      modalVisible,
      selectedDate,
      selectedProjects,
      selectedCategories,
      selectedStatuses,
      selectedPriorities,
      faultCategories,
      faultStatuses,
      faultPriorities,
      projectFilesList,
      projectFilesWithFaultsList,
      selectedFault,
      isLoadingProjects
    }
  }
}
</script>

<style scoped lang="scss">
.generalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
  min-width: 30vw;

  .constructionSiteCardWrapper {
    height: 370px;
    width: 50%;
    background: white;
    border-radius: 25px;

    .image {
      position: relative;
      height: 200px;
      -webkit-border-top-left-radius: 25px;
      -webkit-border-top-right-radius: 25px;
      -moz-border-radius-topleft: 25px;
      -moz-border-radius-topright: 25px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .no-image {
      position: relative;
      height: 200px;
      -webkit-border-top-left-radius: 25px;
      -webkit-border-top-right-radius: 25px;
      -moz-border-radius-topleft: 25px;
      -moz-border-radius-topright: 25px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;

      .no-image-text{
        position: relative;
        border-style: solid;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-color: #f8f8f8;
        border-width: 1px;
        height: 200px;
        color: gray !important;
      }
    }

    .details {
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  }
}
.filteredWrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55vh;
  padding-bottom: 30px;
  min-width: 30vw;

  .p-multiselect{
    width: 30vw;
  }
}
.modalButtonSection {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  Button:nth-child(2){
    margin-left: 10px;
  }
}
.faultWrapper{
  display: flex;
  flex-direction: column;
  height: 55vh;
  min-width: 30vw;

  h4{
    padding-bottom: 5px;
  }

}
</style>
