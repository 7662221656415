import axiosInstance from '@/services/api'
import AuthService from '@/services/auth.service'
import tokenService from '@/services/token.service'
import router from '@/router'

const setup = (token) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = tokenService.getToken()

      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      if (err.response.status === 401) {
        await AuthService.logout()
        await router.push('/')
      }
      return Promise.reject(err)
    })
}

export default setup
