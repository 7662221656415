<template>
  <div class="projectFilesContentWrapper">
    <div class="p-grid">
      <div class="p-col-12 p-md-10 p-sm-12 box">
        <Button @click="toggleProjectFileModal" label="Dodaj nowy projekt" icon="pi pi-plus" iconPos="left"
                :disabled="isLoading || constructionSite[0].role === 'Worker'"/>
      </div>
      <div class="p-col-12 p-md-10 p-sm-12 box">
        <span class="p-input-icon-left">
            <i class="pi pi-search"/>
            <InputText type="text" v-model="localSearchValue" placeholder="Wyszukaj projektu" :disabled="isLoading"/>
        </span>
      </div>
      <div v-if="isLoading" class="p-col-12 p-lg-4 p-md-10">
        <SkeletonCard/>
      </div>
      <div v-else-if="!isLoading && projectFiles?.length > 0" class="p-col-12 p-lg-4 p-md-10"
        v-for="projectFile in projectFiles"
        :key="projectFile.id">
        <ProjectFileContentCard
          :projectFile="projectFile"
          :constructionSiteId="constructionSiteId"
        />
      </div>
      <div v-else-if="!isLoading && projectFiles?.length === 0" class="p-col-12 p-lg-4 p-md-10">
        Obiekt nie posiada żadnych projektów.
      </div>
    </div>
  </div>

  <AddNewProjectModal
    v-model:visible="newProjectFileModal"
    v-model:modelValue1="newProjectFileType"
    v-model:modelValue2="newProjectFileName"
    v-model:file="newProjectFileFile"
    :toggle="toggleProjectFileModal"
    :submit="submitNewProjectModal"
    header="Dodawanie nowego projektu"
  />

</template>

<script>
import { computed, ref } from 'vue'

import SkeletonCard from '@/components/SkeletonCard'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import ProjectFileContentCard from '@/components/project_file/ProjectFileContentCard'
import AddNewProjectModal from '@/components/modals/AddNewProjectModal'
import { useToast } from 'primevue/usetoast'
import { useStore } from 'vuex'
import router from '@/router'
import { clearFileName, createId } from '@/utils/util'
import ProjectFiles from '@/models/project_files_model'

export default {
  name: 'ProjectFileContent',
  props: {
    isLoading: {
      type: Boolean,
      required: false
    },
    projectFiles: {
      required: true
    },
    constructionSiteId: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  components: {
    AddNewProjectModal,
    ProjectFileContentCard,
    SkeletonCard,
    Button,
    InputText
  },
  setup (props, { emit }) {
    const toast = useToast()
    const store = useStore()
    const newProjectFileModal = ref(false)
    const newProjectFileType = ref('')
    const newProjectFileName = ref('')
    const newProjectFileFile = ref(null)
    const constructionSite = computed(() => store.state.constructionSiteStore.constructionSites.filter(constuctionSite => constuctionSite.id === props.constructionSiteId))
    const localSearchValue = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    const toggleProjectFileModal = () => {
      newProjectFileModal.value = !newProjectFileModal.value
      newProjectFileType.value = ''
      newProjectFileName.value = ''
      newProjectFileFile.value = null
    }

    const submitNewProjectModal = async () => {
      if (newProjectFileFile.value !== null) {
        const fileExtension = '.' + newProjectFileFile.value.name.substr(newProjectFileFile.value.name.lastIndexOf('.') + 1).toLowerCase().trim()

        if (fileExtension !== '.png' && fileExtension !== '.svg' && fileExtension !== '.jpg' && fileExtension !== '.jpeg') {
          toast.add({
            severity: 'warn',
            summary: 'Nieobsługiwany plik',
            detail: 'Obsługiwanymi rozszerzeniami plików są: .png, .jpg, .svg',
            life: 3000
          })
        } else {
          const newProjectFile = new ProjectFiles({ id: createId(), name: newProjectFileName.value, type: newProjectFileType.value, extension: fileExtension, faults: [] })

          const formData = new FormData()
          formData.append('file', newProjectFileFile.value)
          formData.append('id', createId())
          formData.append('fileName', clearFileName(newProjectFileFile.value.name))

          await store.dispatch('projectFileStore/add', {
            constructionSiteId: props.constructionSiteId,
            projectFile: newProjectFile,
            formData: formData,
            file: newProjectFileFile.value
          }).then(
            () => {
              toast.add({
                severity: 'success',
                summary: 'Dodawanie projektu',
                detail: 'Pomyślnie dodano projekt.',
                life: 3000
              })
            },
            async (error) => {
              if (error.status === 401) {
                await router.push('/')
              }
              toast.add({
                severity: 'warn',
                summary: 'Dodawanie projektu',
                detail: error.message,
                life: 3000
              })
            }
          )
        }
      } else {
        toast.add({
          severity: 'warn',
          summary: 'Nie znaleziono pliku',
          detail: 'Spróbuj ponownie, ponieważ plik nie został rozpoznany.',
          life: 3000
        })
      }

      toggleProjectFileModal()
      newProjectFileType.value = ''
      newProjectFileName.value = ''
      newProjectFileFile.value = null
    }

    return {
      localSearchValue,
      newProjectFileModal,
      toggleProjectFileModal,
      submitNewProjectModal,
      newProjectFileType,
      newProjectFileName,
      newProjectFileFile,
      constructionSite
    }
  }
}
</script>

<style lang="scss" scoped>
.projectFilesContentWrapper {
  padding: 3% 18%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .box:nth-child(2) {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 5px;

    .box {
      justify-content: center;
    }
  }
}
</style>
