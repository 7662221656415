<template>
  <Dialog header="Dane użytkownika" v-model:visible="modalVisible" :modal="true">
    <div class="p-d-flex p-flex-column modalContentWrapper">
      <div class="p-d-flex p-dir-col p-mt-2">
        <div class="p-d-flex p-dir-row p-jc-between p-mb-1">
          <span>Imię: </span>
          <span>{{ user.name }}</span>
        </div>
        <div class="p-d-flex p-dir-row p-jc-between p-mb-1">
          <span>Nazwisko: </span>
          <span>{{ user.surname }}</span>
        </div>
        <div class="p-d-flex p-dir-row p-jc-between p-mb-1">
          <span>Firma: </span>
          <span>{{ user.company }}</span>
        </div>
        <div class="p-d-flex p-dir-row p-jc-between p-mb-1">
          <span>Dział: </span>
          <span>{{ user.department }}</span>
        </div>
        <div class="p-d-flex p-dir-row p-jc-between p-mb-1">
          <span>Stanowisko: </span>
          <span>{{ user.position }}</span>
        </div>
        <div class="p-d-flex p-dir-row p-jc-between p-mb-1">
          <span>Miasto: </span>
          <span>{{ user.city }}</span>
        </div>
        <div class="p-d-flex p-dir-row p-jc-between p-mb-1">
          <span>Ulica: </span>
          <span>{{ user.street }}</span>
        </div>
        <div class="p-d-flex p-dir-row p-jc-between p-mb-1">
          <span>Numer budynku: </span>
          <span>{{ user.addressNumber }}</span>
        </div>
        <div class="p-d-flex p-dir-row p-jc-between p-mb-1">
          <span>Kod pocztowy: </span>
          <span>{{ user.zipCode }}</span>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { computed, ref } from 'vue'
import Dialog from 'primevue/dialog'

export default {
  name: 'UserModal',
  components: {
    Dialog
  },
  props: {
    toggle: {
      type: Function,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const modalVisible = computed({
      get () {
        return props.visible
      },
      set (value) {
        emit('update:visible', value)
      }
    })

    return { loading, modalVisible }
  }
}
</script>

<style lang="scss" scoped>
.modalContentWrapper{
  width: 300px;
  padding-bottom: 20px;
}
</style>
